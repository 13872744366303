import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Crypto = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M9 6h2M9 25h2M14 6V3M18 6V3M14 28v-3M18 28v-3M11 15h9a5 5 0 110 10h-9V6h7.5a4.5 4.5 0 110 9"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M9 6h2M9 25h2M14 6V3M18 6V3M14 28v-3M18 28v-3M11 15h9a5 5 0 110 10h-9V6h7.5a4.5 4.5 0 110 9"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M9 6h2M9 25h2M14 6V3M18 6V3M14 28v-3M18 28v-3M11 15h9a5 5 0 110 10h-9V6h7.5a4.5 4.5 0 110 9"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M9 6h2M9 25h2M14 6V3M18 6V3M14 28v-3M18 28v-3M11 15h9a5 5 0 110 10h-9V6h7.5a4.5 4.5 0 110 9" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Crypto;
