import { useCallback, useEffect, useState } from 'react';

import { Discrepancy, OffsetAfter, OffsetsApiService } from 'services/offsets';
import { getSelectOptions } from 'utils';

const api = new OffsetsApiService();

export function useLists() {
  const [cpList, setCpList] = useState<string[]>([]);
  const [cpAccountList, setCpAccountList] = useState<string[]>([]);
  const [leList, setLeList] = useState<string[]>([]);

  const getLists = async () => {
    const cpResponse = await api.getCpList();
    setCpList(cpResponse);
    const cpAccountResponse = await api.getCpAccountList();
    setCpAccountList(cpAccountResponse);
    const leResponse = await api.getLeList();
    setLeList(leResponse);
  };

  useEffect(() => {
    getLists();
  }, []);

  return {
    cpOptions: getSelectOptions(cpList),
    leOptions: getSelectOptions(leList),
    cpAccountOptions: getSelectOptions(cpAccountList),
  };
}

export function useOffsetAfters(selectedOffset: Discrepancy | null) {
  const [offsetAfters, setOffsetAfters] = useState<OffsetAfter[]>([]);
  const [offsetAftersIsLoading, setOffsetAftersIsLoading] = useState(false);

  const fetchOffsetAfters = useCallback(async () => {
    if (selectedOffset) {
      setOffsetAftersIsLoading(true);
      const result = await api.getOffsetAfters({
        reportDate: selectedOffset?.report_date,
        symbolId: selectedOffset?.symbol_id,
      });
      setOffsetAfters(result);
      setOffsetAftersIsLoading(false);
    }
  }, [selectedOffset, setOffsetAftersIsLoading, setOffsetAfters]);

  useEffect(() => {
    fetchOffsetAfters();
  }, [selectedOffset]);

  return { offsetAfters, offsetAftersIsLoading };
}
