import { FC, useEffect, useMemo, useState } from 'react';
import { Button, Notification, Select } from 'react-ui-kit-exante';

import { FilterValueType, EmirApiService, ScriptItem } from 'services/emir';

import { EmirScriptItem } from './ScriptParamItem';

const api = new EmirApiService();

export const RunScripts: FC<{ onReloadData: VoidFunction }> = ({
  onReloadData,
}) => {
  const [scripts, setScripts] = useState<ScriptItem[]>([]);
  const [selectScriptName, setSelectScriptName] = useState<string>('');
  const [paramValues, setParamValues] = useState<
    Record<string, string | boolean>
  >({ valid: false });

  useEffect(() => {
    api
      .getScripts()
      .then((res) => {
        setScripts(res.scripts);
      })
      .catch(() => {
        Notification.error({ title: 'Scripts no load' });
      });
  }, []);

  useEffect(() => {
    setParamValues({});
  }, [selectScriptName]);

  const selectScriptParams = useMemo(() => {
    const foundScriptParams = scripts.find(
      (i) => i.name === selectScriptName,
    )?.params;
    return foundScriptParams
      ? Object.keys(foundScriptParams).map((i) => ({
          name: i,
          type: foundScriptParams[i].type,
          choices: foundScriptParams[i].choices,
        }))
      : [];
  }, [scripts, selectScriptName]);

  const handleRunScript = async () => {
    const paramsObject: Record<string, string | boolean> =
      selectScriptParams.reduce(
        (acc, i) =>
          i.type === FilterValueType.BOOLEAN
            ? { ...acc, [i.name]: false }
            : { ...acc, [i.name]: '' },
        {},
      );

    try {
      const payload = {
        ...paramsObject,
        ...paramValues,
        script: selectScriptName,
      };
      await api.runScript(payload);
      onReloadData();
      Notification.success({
        title: 'The script has been successfully launched',
      });
    } catch (error) {
      Notification.error({
        title: 'Script run error:',
        description: JSON.stringify(error),
      });
    }
  };

  const options = useMemo(
    () => scripts.map((i) => ({ label: i.name, value: i.name })),
    [scripts],
  );

  return (
    <>
      <Select
        id="selectScripts"
        fullWidth
        value={selectScriptName}
        onChange={(event) => {
          setSelectScriptName(event.target.value);
        }}
        options={options}
        placeholder="Select script"
        sx={{
          width: 300,
        }}
      />
      {!!selectScriptParams.length &&
        selectScriptParams.map((item) => (
          <div key={item.name} className="text-left mt-4">
            <EmirScriptItem
              values={paramValues}
              setValues={setParamValues}
              type={item.type}
              fieldName={item.name}
              choices={item.choices}
            />
          </div>
        ))}
      {!!selectScriptName && (
        <Button fullWidth className="mt-4" onClick={handleRunScript}>
          run script
        </Button>
      )}
    </>
  );
};
