import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { INITIAL_URL } from 'constants/routes';
import { Metrics as IMetrics } from 'services/emir';

export const Metrics: FC<{
  metrics: IMetrics;
  reportDate: string;
}> = ({ metrics, reportDate }) => {
  const getItem = (field: 'positions' | 'trades') =>
    Object.keys(metrics[field])
      .filter((i) => i !== 'total')
      .map((key) => {
        const value = metrics[field][key];
        return {
          title: `${key}: ${value}`,
          link: `${INITIAL_URL}/emir/${field}?status=${key}&${
            field === 'positions' ? 'created_date' : 'trade_date'
          }=${reportDate},${reportDate}`,
        };
      });
  const metricSections = useMemo(
    () => [
      {
        title: 'Positions',
        items: getItem('positions'),
      },
      {
        title: 'Trades',
        items: getItem('trades'),
      },
    ],
    [metrics, ''],
  );
  return (
    <div className="mui-row w-100">
      {metricSections.map((i) => (
        <div className="mui-col-md-6" key={i.title}>
          <p className="m-0 mt-1">{i.title}</p>
          {i.items.map(
            (item) =>
              item.link && (
                <div key={item.link}>
                  <Link target="_blank" to={item.link}>
                    {item.title}
                  </Link>
                </div>
              ),
          )}
        </div>
      ))}
    </div>
  );
};
