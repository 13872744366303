import { FC, useCallback, useMemo } from 'react';
import {
  Table,
  useTableData,
  IOnFetchArguments,
  ISortBy,
} from 'react-ui-kit-exante';

import { defaultOrdering, pageSizes } from 'constants/tables';
import { MifirApiService, SftrReportsTable } from 'services/mifir';
import { calculateCountOfPages } from 'utils';

import { getTradesTableColumns } from './SftrReportsPage.utils';

const api = new MifirApiService();

export const SftrReportsPage: FC = () => {
  const getReports = useCallback(
    (props: IOnFetchArguments) => api.getSftrReports(props),
    [],
  );

  const tableData = useMemo(
    () => ({
      data: { onFetch: getReports },
      pagination: { getDefaultPagination: () => ({ limit: 10, skip: 0 }) },
    }),
    [getReports],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    setSorting,
  } = useTableData<SftrReportsTable>(tableData);

  const total = data?.pagination.total || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const columns = useMemo(
    () =>
      getTradesTableColumns({ onFilter: setFilter, onRemove: removeFilter }),
    [removeFilter, setFilter],
  );
  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
    }),
    [filters, resetFilters],
  );
  const handleSorting = useCallback(
    (sortingArray: ISortBy[]) => {
      setSorting(sortingArray);
    },
    [setSorting],
  );

  return (
    <Table
      tableId="SftrReportsTable"
      title="Sftr Reports"
      data={data?.reports ?? []}
      columns={columns}
      filteringProps={filteringProps}
      defaultSortBy={defaultOrdering}
      isLoading={isLoading}
      showScrollbar
      hasFilters
      hasPagination
      showTableInfo
      manualSortBy
      onSort={handleSorting}
      isFlexLayout
      pageSizes={pageSizes}
      serverPaginationProps={{
        pageIndex: page,
        pageCount,
        pageSize: limit,
        total,
        setPage,
        setPageSize: setLimit,
      }}
    />
  );
};
