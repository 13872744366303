import { getApiUrl, isStage, isLocalHost, isTwino } from '../api';

const STAGE_ENV = 'mifir-reports-stage.exante.eu';
const PROD_ENV = 'reporting.exante.eu';
const TWINO_ENV = 'compliance-reports.twinozone.com';

const RUN_UI_API_URL = window?.REG_REPORTING_UI?.api_urls?.MIFIR;

export const getMifidEndpoint = () => {
  if (RUN_UI_API_URL) {
    return getApiUrl(RUN_UI_API_URL);
  }
  if (isTwino) {
    return getApiUrl(TWINO_ENV);
  }
  if (isStage || isLocalHost) {
    return getApiUrl(STAGE_ENV);
  }
  return getApiUrl(PROD_ENV);
};
export enum Urls {
  REPORTS = 'mifid/reports',
  ENTRY = 'mifid/entry',
  SFTR_TRADES = 'sftr/trades',
  SFTR_REPORTS = 'sftr/reports',
  REPORT_STATS = 'report_stats',
  REPORT_QUARTER_STATS = 'report_capital_stats',
}
