import { IColumn, ISelectOption } from 'react-ui-kit-exante';

import { Recall, Report, Security } from 'services/stock-lending';
import { ColumnsWithFilters } from 'types';

const fieldOptions: ISelectOption[] = [
  { label: 'Corporate action', value: 'Corporate action' },
  {
    label: 'Available less then lent out',
    value: 'Available less then lent out',
  },
  { label: 'Client request', value: 'Client request' },
];
export const getReportColumns = ({
  onRemove,
  onFilter,
}: ColumnsWithFilters) => {
  const columns: IColumn<Report>[] = [
    {
      Header: 'Report',
      accessor: 'report_name',
      with: 200,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Type',
      accessor: 'report_type',
      with: 200,
      onRemove,
      onFilter,
      disableSortBy: true,
    },
    {
      Header: 'Date',
      accessor: 'report_date',
      disableFilters: true,
      disableSortBy: true,
    },
  ];
  return columns;
};

export const recallColumns: IColumn<Recall>[] = [
  {
    Header: 'ASSET',
    accessor: 'asset',
    disableSortBy: true,
  },
  {
    Header: 'lent out',
    accessor: 'lent_out',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'recall quantity',
    accessor: 'recall_quantity',
    disableFilters: true,
    disableSortBy: true,
    editParams: {
      editable: () => true,
    },
  },
  {
    Header: 'Rate %',
    accessor: 'rate',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'reason field',
    accessor: 'reason_field',
    disableFilters: true,
    disableSortBy: true,
    editParams: {
      editable: () => true,
      inputType: 'select',
      options: fieldOptions,
    },
  },
  {
    Header: 'counterparty',
    accessor: 'counterparty',
    disableFilters: true,
    disableSortBy: true,
  },
];

export const securitiesColumns: IColumn<Security>[] = [
  {
    Header: 'ASSET',
    accessor: 'symbol_ticker',
    disableSortBy: true,
  },
  {
    Header: 'Total Nostro',
    accessor: 'total_nostro',
    disableSortBy: true,
  },
  {
    Header: 'Total Corporate',
    accessor: 'total_corporate',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Quantity',
    accessor: 'quantity',
    disableFilters: true,
    disableSortBy: true,
    editParams: {
      editable: () => true,
    },
  },
  {
    Header: 'Lent out',
    accessor: 'lent_out',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'available to lend',
    accessor: 'available_to_lend',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'rate from cp',
    accessor: 'rate_from_cp',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Rate %',
    accessor: 'rate',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Reward',
    accessor: 'reward',
    disableFilters: true,
    disableSortBy: true,
  },
];
