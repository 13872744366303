import dayjs from 'dayjs';
import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Notification,
  Input,
  Select,
  Modal,
  DatePicker,
  ISelectOption,
} from 'react-ui-kit-exante';

import { DATE_FORMAT } from 'constants/date';
import { AddAdjustmentPayload, OffsetsApiService } from 'services/offsets';

interface Props {
  reloadData: VoidFunction;
  onClose: VoidFunction;
  leOptions: ISelectOption[];
  cpOptions: ISelectOption[];
  cpAccountOptions: ISelectOption[];
  filterValues: {
    reportDate: Date;
    le?: string;
    cp?: string;
    cpAcc?: string;
  };
}

const api = new OffsetsApiService();
export const AddAdjustment: FC<Props> = ({
  reloadData,
  onClose,
  leOptions,
  cpOptions,
  cpAccountOptions,
  filterValues,
}) => {
  const { register, handleSubmit, watch } = useForm<
    Omit<AddAdjustmentPayload, 'report_date'>
  >({
    defaultValues: {
      sold: '0',
      bought: '0',
      counterparty_account_id: filterValues.cpAcc,
      counterparty: filterValues.cp,
      legal_entity: filterValues.le,
    },
  });
  const [reportDate, setReportDate] = useState<Date | null>(
    filterValues.reportDate || null,
  );
  const [symbol, setSymbol] = useState('');
  const [account, setAccount] = useState('');

  const values = watch();
  const handleAddAdjustment: SubmitHandler<
    Omit<AddAdjustmentPayload, 'report_date'>
  > = async (data) => {
    try {
      await api.addAdjustment({
        report_date: dayjs(reportDate).format(DATE_FORMAT),
        symbol_id: symbol,
        bought: data.bought,
        sold: data.sold,
        counterparty: data.counterparty,
        counterparty_account_id: data.counterparty_account_id,
        account_id: account,
        legal_entity: data.legal_entity,
      });
      reloadData();
      onClose();
    } catch (error) {
      Notification.error({
        title: 'Add adjustment offset error:',
        description: String(error),
      });
    }
  };

  const handleChangeDate = (date: Date | null) => {
    setReportDate(date);
  };

  return (
    <Modal
      isOpened
      title="Add adjustment"
      onClose={onClose}
      confirmButton={{
        confirmButtonName: 'Add',
        handleConfirm: handleSubmit(handleAddAdjustment),
      }}
    >
      <div className="mt-4">
        <DatePicker
          name="report_date"
          label="Report date"
          value={reportDate}
          onChange={handleChangeDate}
          className="w-100 mb-3"
        />
        <Input
          label="Symbol"
          className="mb-3"
          fullWidth
          value={symbol}
          onChange={(event) => {
            setSymbol(event.target.value.trim());
          }}
        />
        <Input
          label="Account"
          className="mb-3"
          fullWidth
          value={account}
          onChange={(event) => {
            setAccount(event.target.value.trim());
          }}
        />
        <Select
          label="Legal Entity"
          className="mb-3 w-100"
          options={leOptions}
          value={values.legal_entity}
          {...register('legal_entity')}
        />
        <Select
          label="Counterparty"
          className="mb-3 w-100"
          options={cpOptions}
          value={values.counterparty}
          {...register('counterparty')}
        />
        <Select
          label="Counterparty account"
          className="mb-3 w-100"
          options={cpAccountOptions}
          value={values.counterparty_account_id}
          {...register('counterparty_account_id')}
        />
        <Input
          label="Bought"
          className="mb-3"
          fullWidth
          {...register('bought')}
        />
        <Input label="Sold" className="mb-3" fullWidth {...register('sold')} />
      </div>
    </Modal>
  );
};
