import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const SendToRegistrant = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 20a5 5 0 100-10 5 5 0 000 10zM13 4.375c1.967-.51 4.033-.51 6 0M4.436 12.788a11.943 11.943 0 013-5.195M19 27.625c-1.967.51-4.033.51-6 0M24.564 7.59a11.944 11.944 0 013 5.198"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27.565 19.213a12 12 0 01-3.54 5.709 9.001 9.001 0 00-16.05 0 12 12 0 01-3.54-5.71"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 18a5 5 0 100-10 5 5 0 000 10zM13 4.375c1.967-.51 4.033-.51 6 0M4.436 12.788a11.943 11.943 0 013-5.195M19 27.625c-1.967.51-4.033.51-6 0M24.564 7.59a11.944 11.944 0 013 5.198M27.565 19.213a12 12 0 01-3.54 5.709 9.001 9.001 0 00-16.05 0 12 12 0 01-3.54-5.71"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 18a5 5 0 100-10 5 5 0 000 10zM13 4.375c1.967-.51 4.033-.51 6 0M4.436 12.788a11.943 11.943 0 013-5.195M19 27.625c-1.967.51-4.033.51-6 0M24.564 7.59a11.942 11.942 0 013 5.198M27.565 19.213a12 12 0 01-3.54 5.709 9.001 9.001 0 00-16.05 0 12 12 0 01-3.54-5.71"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M16 20a5 5 0 100-10 5 5 0 000 10zM13 4.375c1.967-.51 4.033-.51 6 0M4.436 12.788a11.943 11.943 0 013-5.195M19 27.625c-1.967.51-4.033.51-6 0M24.564 7.59a11.942 11.942 0 013 5.198"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27.565 19.213a12 12 0 01-3.54 5.709 9.001 9.001 0 00-16.05 0 12 12 0 01-3.54-5.71"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      {...restProps}
    />
  );
};

export default SendToRegistrant;
