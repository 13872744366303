import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import {
  IconButton,
  Notification,
  OnSaveEditableRow,
  Table,
} from 'react-ui-kit-exante';

import { defaultOrdering } from 'constants/tables';
import {
  Recall,
  Security,
  StockLendingApiService,
} from 'services/stock-lending';

import { recallColumns, securitiesColumns } from './utils';

const api = new StockLendingApiService();

interface Props {
  selectedTab: number;
  counterparty: string;
  tableTitle: ReactNode;
}
export const OtherTables: FC<Props> = ({
  selectedTab,
  counterparty,
  tableTitle,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [items, setItems] = useState<(Security | Recall)[]>([]);

  const fetchTableItems = async () => {
    setIsLoading(true);
    if (selectedTab === 1) {
      const response = await api.getRecalls(counterparty);
      setIsLoading(false);
      setItems(response);
      return;
    }
    const response = await api.getSecurities(counterparty);
    setIsLoading(false);
    setItems(response);
  };

  const handleSendToFtp = async () => {
    setIsSending(true);
    try {
      if (selectedTab === 1) {
        await api.sendRecallsToFtp([], '');
      } else {
        await api.sendSecurititesToFtp([], '');
      }
    } catch (error) {
      Notification.error({
        title: 'Send to ftp error: ',
        description: JSON.stringify(error),
      });
    } finally {
      setIsSending(false);
    }
  };

  useEffect(() => {
    fetchTableItems();
  }, [selectedTab, counterparty]);

  const columns = useMemo(
    () => (selectedTab === 1 ? recallColumns : securitiesColumns),
    [selectedTab],
  );

  const displayedColumnKeys = useMemo(
    () => [...columns.map((item) => item.accessor), 'actions'],
    [columns],
  );

  const additionalActions = useMemo(
    () => [
      {
        title: 'Send to ftp',
        component: (
          <IconButton
            disabled={isSending || !items.length}
            onClick={handleSendToFtp}
            iconName="TransferIcon"
            iconColor="action"
            label="Send to ftp"
          />
        ),
      },
    ],
    [selectedTab, items],
  );

  const handleSaveEditableRow: OnSaveEditableRow<Recall | Security> = async (
    previousValues,
    updatedValues,
  ) => {
    const newItems = [...items];

    let identifier = '';
    if (selectedTab === 1) {
      identifier = `${(previousValues as Recall).asset}_${
        (previousValues as Recall).counterparty
      }`;
    }
    if (selectedTab === 0) {
      identifier = (previousValues as Security).symbol_ticker;
    }
    const findIndex = items.findIndex(
      (item) =>
        (selectedTab === 1
          ? `${(item as Recall).asset}_${(item as Recall).counterparty}`
          : (item as Security).symbol_ticker) === identifier,
    );
    if (Number.isInteger(findIndex)) {
      newItems[findIndex] = updatedValues;
    }
    setItems(newItems);
  };

  return (
    <Table
      tableId="stockLending"
      additionalActions={additionalActions}
      title={tableTitle}
      isNotSaveVisibleColumns
      displayedColumnKeys={displayedColumnKeys}
      data={items}
      columns={columns}
      defaultSortBy={defaultOrdering}
      isLoading={isLoading}
      rowActions={{
        show: true,
        onSave: handleSaveEditableRow,
      }}
      showScrollbar
      showTableInfo
      manualSortBy
      isFlexLayout
    />
  );
};
