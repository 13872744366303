const branchNameSearch = (p: string) => /BPA-\d*|release/.test(p);

export const addBaseTag = () => {
  const baseTag = document.createElement('base');
  const path = window.location.pathname.split('/');
  const branchIdx = path.findIndex(branchNameSearch);
  if (branchIdx !== -1) {
    baseTag.href = `${
      window.location.origin + path.slice(0, branchIdx + 1).join('/')
    }/`;
    document.head.prepend(baseTag);
  }
};

export function getBaseName() {
  if (window.location.href.includes('BPA-')) {
    return window.location.href.split('/').slice(0, 4)[3];
  }
  if (window.location.href.includes('release')) {
    return 'release';
  }
  return '';
}
