import { Button as MaterialButton } from '@mui/material';

import { blockNonNativeProps } from '../../../../helpers';
import { styled } from '../../../../theme';

import type { ButtonProps } from './ActionsCell.types';

export const Button = styled(MaterialButton, {
  shouldForwardProp: blockNonNativeProps('iconColor'),
})<ButtonProps>(({ theme, iconColor }) => ({
  color: theme.color.icon[iconColor || 'secondary'],
  minWidth: 'auto',
  height: 'min-content',
  '& > svg': {
    width: 16,
    height: 16,
  },
  '&:hover': {
    color: theme.color.icon.primary,
  },
})) as React.ComponentType<ButtonProps>;

export const Container = styled('div')(() => ({
  width: '100%',
  minWidth: 85,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
}));
