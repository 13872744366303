import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Sign = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M4.567 27.17l17.431-2.905a1 1 0 00.771-.635l2.798-7.46-10-10-7.46 2.797a1 1 0 00-.635.772l-2.905 17.43z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M25.567 16.17l3.707-3.707a1 1 0 000-1.414l-8.582-8.586a1 1 0 00-1.414 0L15.567 6.17M15.067 19.17a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM4.568 27.17l8.732-8.733"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M4.567 29.17l17.431-2.905a1 1 0 00.771-.635l2.798-7.46-10-10-7.46 2.797a1 1 0 00-.635.772l-2.905 17.43z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M25.567 18.17l3.707-3.707a1 1 0 000-1.414l-8.582-8.586a1 1 0 00-1.414 0L15.567 8.17M15.067 21.17a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM4.568 29.17l8.732-8.733"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M4.567 29.17l17.431-2.905a1 1 0 00.771-.635l2.798-7.46-10-10-7.46 2.797a1 1 0 00-.635.772l-2.905 17.43z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M25.567 18.17l3.707-3.707a1 1 0 000-1.414l-8.582-8.586a1 1 0 00-1.414 0L15.567 8.17M15.067 21.17a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM4.568 29.17l8.732-8.733"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M4.567 27.17l17.431-2.905a1 1 0 00.771-.635l2.798-7.46-10-10-7.46 2.797a1 1 0 00-.635.772l-2.905 17.43z" />
            <path d="M25.567 16.17l3.707-3.707a1 1 0 000-1.414l-8.582-8.586a1 1 0 00-1.414 0L15.567 6.17M15.067 19.17a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM4.568 27.17l8.732-8.733" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default Sign;
