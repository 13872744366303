import cn from 'classnames';
import { FC } from 'react';

import CloseIcon from '../../../assets/closeNew.svg';
import styles from '../Button.module.css';
import { DEFAULT_SIZE } from '../constants';
import { ICloseButtonProps } from '../types';

import { StyledButton } from './CloseButton.styled';

/**
 * This component is deprecated.
 * Use `IconButton` with iconName=CloseIcon instead.
 *
 * @deprecated
 */

export const CloseButton: FC<ICloseButtonProps> = ({
  size = DEFAULT_SIZE,
  iconClassName,
  ...rest
}) => {
  const classNames = cn(styles[`${size.toUpperCase()}Size`], iconClassName);

  return (
    <StyledButton {...rest} size={size}>
      <img className={classNames} alt="close" src={CloseIcon} />
    </StyledButton>
  );
};
