import { FC, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Table,
  useTableData,
  IOnFetchArguments,
  ISortBy,
} from 'react-ui-kit-exante';

import { defaultOrdering, pageSizes } from 'constants/tables';
import { MifirApiService, EntriesTable, Entry } from 'services/mifir';
import { calculateCountOfPages } from 'utils';

import { getEntriesTableColumns } from './EntriesPageUtils';

const api = new MifirApiService();

export const MifidEntriesPage: FC = () => {
  const { reportId } = useParams<{ reportId: string }>();
  const getEntries = useCallback(
    (props: IOnFetchArguments) => api.getReportEntries(props, String(reportId)),
    [],
  );

  const prepareFiltersForParams = useCallback(
    (params: Record<string, unknown>): Record<string, unknown> => {
      const filterParams = getEntriesTableColumns({}).map(
        (column) => column.accessor,
      );

      return Object.keys(params).reduce(
        (acc, param) =>
          filterParams.includes(param as keyof Entry)
            ? { ...acc, [param]: params[param] }
            : acc,
        {},
      );
    },
    [],
  );

  const tableData = useMemo(
    () => ({
      data: { onFetch: getEntries },
      pagination: { getDefaultPagination: () => ({ limit: 10, skip: 0 }) },
      filters: {
        prepareFiltersForParams,
      },
    }),
    [getEntries],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    setSorting,
  } = useTableData<EntriesTable>(tableData);

  const total = data?.pagination.total || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );
  const columns = useMemo(
    () =>
      getEntriesTableColumns({ onFilter: setFilter, onRemove: removeFilter }),
    [setFilter, removeFilter],
  );
  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
    }),
    [filters, resetFilters],
  );
  const handleSorting = useCallback(
    (sortingArray: ISortBy[]) => {
      setSorting(sortingArray);
    },
    [setSorting],
  );

  return (
    <div className="mui-row mt-2">
      <div className="mui-col-md-12">
        <Table
          tableId="mifidEntriesTable"
          title="Mifid Entries"
          data={data?.entries ?? []}
          columns={columns}
          filteringProps={filteringProps}
          defaultSortBy={defaultOrdering}
          isLoading={isLoading}
          showScrollbar
          hasFilters
          hasPagination
          showTableInfo
          manualSortBy
          onSort={handleSorting}
          isFlexLayout
          pageSizes={pageSizes}
          serverPaginationProps={{
            pageIndex: page,
            pageCount,
            pageSize: limit,
            total,
            setPage,
            setPageSize: setLimit,
          }}
        />
      </div>
    </div>
  );
};
