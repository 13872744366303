export const getSelectOptions = (
  array: Record<string, any>[] | string[] | Record<string | number, unknown>,
  labelField = '',
  valueField = '',
) => {
  if (Array.isArray(array)) {
    return array.map((item) =>
      typeof item === 'string'
        ? {
            label: item,
            value: item,
          }
        : {
            label: item[labelField],
            value: String(item[valueField]),
          },
    );
  }
  if (typeof array === 'object') {
    return Object.keys(array).map((i) => ({
      label: array[i],
      value: i,
    }));
  }
  return [];
};
