import { IColumn } from 'react-ui-kit-exante';

import { minColumnWidth as minWidth } from 'constants/tables';
import { SftrReport } from 'services/mifir';
import { ColumnsWithFilters } from 'types/tables';

const columnWidth = 300;
export function getTradesTableColumns({
  onFilter,
  onRemove,
}: ColumnsWithFilters) {
  const columns: IColumn<SftrReport>[] = [
    {
      Header: 'Id',
      accessor: 'id',
      minWidth: 150,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Uid',
      accessor: 'uid',
      minWidth: 150,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Date',
      accessor: 'date',
      minWidth,
      type: 'dateRange',
      onFilter,
      onRemove,
    },
    {
      Header: 'Error',
      accessor: 'error',
      width: columnWidth,
      minWidth,
      disableFilters: true,
    },
    {
      Header: 'Last response',
      accessor: 'last_response',
      minWidth,
      type: 'dateRange',
      onFilter,
      onRemove,
    },
    {
      Header: 'Legal entity id',
      accessor: 'legal_entity_id',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Accepted records',
      accessor: 'accepted_records',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Rejected records',
      accessor: 'rejected_records',
      minWidth,
      disableFilters: true,
    },
    {
      Header: 'Total records',
      accessor: 'total_records',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Status',
      accessor: 'status_id',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
  ];
  return columns;
}
