import { getApiUrl, isStage, isLocalHost, isTwino } from '../api';

const STAGE_ENV = 'offset-management-stage.exante.eu';
const PROD_ENV = 'offset-management.exante.eu';
const TWINO_ENV = 'offset-management.exante.eu';

const RUN_UI_API_URL = window?.REG_REPORTING_UI?.api_urls?.OFFSETS;
export const getOffsetsEndpoint = () => {
  if (RUN_UI_API_URL) {
    return getApiUrl(RUN_UI_API_URL);
  }
  if (isTwino) {
    return getApiUrl(TWINO_ENV);
  }
  if (isStage || isLocalHost) {
    return getApiUrl(STAGE_ENV);
  }
  return getApiUrl(PROD_ENV);
};
export enum Urls {
  ADJUSTMENTS = 'adjustments',
  OFFSETS = 'offsets',
  OFFSET_AFTERS = 'offsets/position_after_by_accounts',
  DISCREPANCIES = 'offsets/discrepancies',
  REPORTS = 'reports',
  CP_LIST = 'distincts/counterparties',
  CP_ACCOUNT_LIST = 'distincts/counterparty_account_ids',
  LE_LIST = 'distincts/legal_entities',
}
