import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { Application } from './components/Application';
import { ComposedThemeProvider } from './theme/ComposedThemeProvider';
import { addBaseTag } from './utils/routingHelpers';

export const initApp = (node?: Element) => {
  const rootNode = node || document.getElementById('root');

  if (!rootNode) {
    throw new Error('Root element was not found');
  }

  const root = createRoot(rootNode);

  window.REG_REPORTING_UI = {
    ...(window.REG_REPORTING_UI || {}),
    root,
  };

  root.render(
    <StrictMode>
      <ComposedThemeProvider>
        <Application />
      </ComposedThemeProvider>
    </StrictMode>,
  );
};

window.REG_REPORTING_UI = {
  ...window.REG_REPORTING_UI,
  render: (node: Element) => initApp(node),
};

addBaseTag();

if (!window.runUIhistoryContainer) {
  initApp();
}
