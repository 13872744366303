export interface Offset {
  id: number;
  report_date: string;
  account_id: string;
  symbol_id: string;
  legal_entity: string;
  counterparty: string;
  counterparty_account_id: string;
  bought: string;
  sold: string;
  position_before: string;
  position_after: string;
  offset: string | null;
  gs_quantity: string;
  position_after_gs_diff: string;
}

export interface OffsetAfter {
  account_id: string;
  symbol_id: string;
  counterparty: string;
  counterparty_account_id: string;
  legal_entity: string;
  report_date: string;
  position_after: string;
}

export interface Discrepancy {
  report_date: string;
  legal_entity: string;
  counterparty: string;
  counterparty_account_id: string;
  symbol_id: string;
  position_after_grouped: string;
  gs_quantity_grouped: string;
  cp_qty_grouped: string;
  cp_gs_diff: string;
  gs_quantity_net: string;
}

export enum Statuses {
  PROCESSED = 'processed',
  NOT_SENT = 'Not sent',
  ACTIVE = 'active',
}
export interface ActivityReport {
  id: number;
  uuid: string;
  counterparty: string;
  legal_entity: string;
  report_date: string;
  status: Statuses;
}

export interface Adjustment {
  id: number;
  report_date: string;
  symbol_id: string;
  account_id: string;
  counterparty: string;
  legal_entity: string;
  counterparty_account_id: string;
  bought: string;
  sold: string;
}
