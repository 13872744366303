import cn from 'classnames';
import { Link } from 'react-router-dom';
import { TableProps, ICellValue } from 'react-ui-kit-exante';

import { INITIAL_URL } from 'constants/routes';
import { minColumnWidth as minWidth } from 'constants/tables';
import { Trade } from 'services/emir';
import { ColumnsWithFilters } from 'types/tables';

const successStatuses = ['entry_created', 'entry created'];
const columnWidth = 300;
export function getTradesTableColumns({
  onFilter,
  onRemove,
}: ColumnsWithFilters) {
  const columns: TableProps<Trade>['columns'] = [
    {
      Header: 'Uid',
      accessor: 'uid',
      minWidth: 150,
      Cell: ({ row }: ICellValue<Trade>) => (
        <Link
          to={`${INITIAL_URL}/emir/trade/${row.values.uid}`}
          className={cn({
            disabled: !successStatuses.includes(row.original.status ?? ''),
          })}
          target="_blank"
        >
          {row.values.uid}
        </Link>
      ),
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Position id',
      accessor: 'position_id',
      minWidth: 40,
      Cell: ({ row }: ICellValue<Trade>) => (
        <Link
          to={`${INITIAL_URL}/emir/position/${row.values.position_id}`}
          className={cn({
            disabled: !successStatuses.includes(row.original.status ?? ''),
          })}
          target="_blank"
        >
          {row.values.position_id}
        </Link>
      ),
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Trade date',
      accessor: 'trade_date',
      minWidth,
      type: 'dateRange',
      onFilter,
      onRemove,
    },
    {
      Header: 'Trade time',
      accessor: 'trade_time',
      width: columnWidth,
      minWidth,
      disableFilters: true,
    },
    {
      Header: 'Value date',
      accessor: 'value_date',
      minWidth,
      type: 'dateRange',
      onFilter,
      onRemove,
    },
    {
      Header: 'Account ID',
      accessor: 'account',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Status',
      accessor: 'status',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Internal status',
      accessor: 'internal_status',
      minWidth,
      disableFilters: true,
    },
    {
      Header: 'Side',
      accessor: 'side',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Symbol ID',
      accessor: 'symbol',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'ISIN',
      accessor: 'isin',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Symbol Type',
      accessor: 'symbol_type',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Quantity',
      accessor: 'qty',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Price',
      accessor: 'price',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Traded volume',
      accessor: 'value',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Currency',
      accessor: 'ccy',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Error',
      accessor: 'error',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Counterparty account ID',
      accessor: 'cp_account',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Execution counterparty',
      accessor: 'execution_cp',
      width: columnWidth,
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Settlement counterparty',
      accessor: 'settlement_cp',
      width: columnWidth,
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Legal entity',
      accessor: 'entity',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Order ID',
      accessor: 'order_id',
      width: columnWidth,
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Status date',
      accessor: 'status_date',
      minWidth,
      type: 'dateRange',
      onFilter,
      onRemove,
    },
  ];
  return columns;
}
