import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import {
  Table,
  useTableData,
  IOnFetchArguments,
  DownloadIcon,
  Loader,
  Notification,
} from 'react-ui-kit-exante';

import { defaultOrdering, pageSizes } from 'constants/tables';
import { StockLendingApiService } from 'services/stock-lending';
import { Urls } from 'services/stock-lending/consts';
import { TableDataWithPagination } from 'types';
import { calculateCountOfPages } from 'utils';

import { getReportColumns } from './utils';

const api = new StockLendingApiService();

interface Props {
  counterparty: string;
  tableTitle: ReactNode;
}

export const HistoryTable: FC<Props> = ({ counterparty, tableTitle }) => {
  const getTableData = useCallback(
    (props: IOnFetchArguments) =>
      api.getReports({
        ...props,
        filtersParams: { ...props.filtersParams, counterparty },
      }),
    [counterparty],
  );

  const tableData = useMemo(
    () => ({
      data: { onFetch: getTableData },
    }),
    [getTableData],
  );

  const {
    data,
    isLoading,
    resetFilters,
    filters,
    removeFilter,
    setFilter,
    limit,
    page,
    setPage,
    setLimit,
  } = useTableData<TableDataWithPagination<any>>(tableData);

  const filterProps = useMemo(
    () => ({
      filters,
      removeAllFilters: resetFilters,
    }),
    [resetFilters, filters],
  );

  const pageCount = useMemo(
    () => calculateCountOfPages(data?.pagination.total || 0, limit),
    [limit, data?.pagination.total],
  );

  const columns = useMemo(
    () =>
      getReportColumns({
        onRemove: removeFilter,
        onFilter: setFilter,
      }),
    [removeFilter, setFilter],
  );

  const [loadingFileName, setLoadingFileName] = useState('');

  const handleLoadReport = async (name: string) => {
    try {
      setLoadingFileName(name);
      const response = await fetch(
        `${api._apiBase + Urls.REPORT_DOWNLOAD}/${name}`,
        {
          headers: api.getBaseHeaders(),
        },
      );
      if (!response.ok) {
        const error = await response.json();
        throw new Error(JSON.stringify(error));
      }
      const result = await response.blob();
      const aElement = document.createElement('a');
      aElement.setAttribute('download', name);
      const href = URL.createObjectURL(result);
      aElement.href = href;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(href);
    } catch (error) {
      Notification.error({
        title: 'Loading file error',
        description: String(error),
      });
    } finally {
      setLoadingFileName('');
    }
  };

  return (
    <Table
      tableId="stokLending"
      title={tableTitle}
      isNotSaveVisibleColumns
      data={data?.items || []}
      columns={columns}
      hasFilters
      filteringProps={filterProps}
      defaultSortBy={defaultOrdering}
      isLoading={isLoading}
      showScrollbar
      hasPagination
      showTableInfo
      manualSortBy
      isFlexLayout
      pageSize={10}
      pageSizes={pageSizes}
      rowActions={{
        show: true,
        additionalActions: (report) => [
          {
            label:
              loadingFileName === report?.report_name ? (
                <Loader />
              ) : (
                <DownloadIcon />
              ),
            onClick: (value) => {
              handleLoadReport(value.report_name);
            },
            title: 'Copy',
          },
        ],
      }}
      serverPaginationProps={{
        pageIndex: page,
        pageCount,
        pageSize: limit,
        total: data?.pagination.total || 0,
        setPage,
        setPageSize: setLimit,
      }}
    />
  );
};
