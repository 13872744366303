import { IOnFetchArguments, Notification } from 'react-ui-kit-exante';

import { Response } from 'types';

import { getUrlFromPayload } from '../api';
import BaseApiService from '../api/Api.service';

import { getLendingEndpoint, Urls } from './consts';
import { Report, Recall, Asset, Security, AvailableAsset } from './types';

export class StockLendingApiService extends BaseApiService {
  _apiBase = getLendingEndpoint();

  async getReports({ params, filtersParams }: IOnFetchArguments) {
    const { sorting, skip, page, limit, ...otherParams } = params;
    try {
      const response = await this.getResource<Response<Report>>(
        getUrlFromPayload(`${Urls.REPORTS}?`, {
          ...otherParams,
          ...filtersParams,
          offset: page * limit,
          limit,
        }),
      );
      return {
        items: response.items,
        pagination: {
          total: response.total,
        },
      };
    } catch (error) {
      Notification.error({
        title: 'Get reports error:',
        description: JSON.stringify(error),
      });
      return {
        items: [],
        pagination: {},
      };
    }
  }

  async fetchReportFile(filename: string) {
    return this.getResource<unknown>(
      getUrlFromPayload(`${Urls.REPORT_DOWNLOAD}/${filename}`, {}),
    );
  }

  async getRecalls(counterparty: string) {
    try {
      const response = await this.getResource<Recall[]>(
        getUrlFromPayload(`${Urls.RECALLS}?`, { counterparty }),
      );
      return response;
    } catch (error) {
      Notification.error({
        title: 'Get recalls error:',
        description: JSON.stringify(error),
      });
      return [];
    }
  }

  async sendRecallsToFtp(assets: Asset[], provider: string) {
    const response = await this.postResource(
      getUrlFromPayload(`${Urls.SEND_RECALLS_TO_FTP}?`, { assets, provider }),
    );
    return response;
  }

  async getSecurities(counterparty: string) {
    try {
      const response = await this.getResource<Security[]>(
        getUrlFromPayload(`${Urls.SECURITIES}?`, { counterparty }),
      );
      return response;
    } catch (error) {
      Notification.error({
        title: 'Get securities error:',
        description: JSON.stringify(error),
      });
      return [];
    }
  }

  async sendSecurititesToFtp(assets: AvailableAsset[], provider: string) {
    const response = await this.postResource(
      getUrlFromPayload(`${Urls.SEND_ASSETS_TO_FTP}?`, { assets, provider }),
    );
    return response;
  }

  async getCounterpartiesList() {
    try {
      const response = await this.getResource<{ name: string }[]>(
        getUrlFromPayload(`${Urls.COUNTERPARTIES}?`, {}),
      );
      return response;
    } catch (error) {
      Notification.error({
        title: 'Get counterparties list error',
        description: JSON.stringify(error),
      });
    }
    return [];
  }
}
