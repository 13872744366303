import { forwardRef } from 'react';
// eslint-disable-next-line import/no-unresolved
import { IMaskInput } from 'react-imask';

import { StyledInput } from './DatePickerInput.styled';
import { IDatePickerInputProps } from './types';

const DatePickerInputMask = forwardRef<HTMLInputElement, any>((props, ref) => {
  const { onChange, ...rest } = props;

  const handleAccept = (
    value: string,
    _: any, // 'react-imask'.InputMask, not used
    inputEvent: InputEvent,
  ) => {
    // Filter out any non-input events
    // Any programmatic changes should not trigger onChange in InputDatePicker
    if (!inputEvent) {
      return;
    }

    onChange({
      target: { name: props.name, value },
      isDefaultPrevented: () => false,
    });
  };

  return <IMaskInput {...rest} onAccept={handleAccept} inputRef={ref} />;
});
DatePickerInputMask.displayName = 'DatePickerInputMask';

export const DatePickerInput = forwardRef<
  HTMLDivElement,
  IDatePickerInputProps
>(({ mask, placeholder, label, onAccept, ...rest }, ref) => {
  const isHiddenLabel = rest.size === 'small';
  return (
    <StyledInput
      {...rest}
      inputRef={ref}
      MaskedInputComponent={DatePickerInputMask}
      placeholder={label}
      inputProps={{
        mask,
        onAccept,
        placeholder,
        label: isHiddenLabel ? label : '',
      }}
    />
  );
});
DatePickerInput.displayName = 'DatePickerInput';
