import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const SendToOnboarding = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            clipPath="url(#prefix__clip0_39483_538663)"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M22 13l-6 3V9M16 28a12 12 0 1112-12M20 28c.459-1.725 2.075-3 4-3 1.925 0 3.541 1.275 4 3" />
            <path d="M24 25a3 3 0 100-6 3 3 0 000 6z" />
          </g>
          <defs>
            <clipPath id="prefix__clip0_39483_538663">
              <path fill="currentColor" d="M0 0h32v32H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            clipPath="url(#prefix__clip0_39483_538661)"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M22 13l-6 3V9M16 28a12 12 0 1112-12M20 28c.459-1.725 2.075-3 4-3 1.925 0 3.541 1.275 4 3" />
            <path d="M24 23a3 3 0 100-6 3 3 0 000 6z" />
          </g>
          <defs>
            <clipPath id="prefix__clip0_39483_538661">
              <path fill="currentColor" d="M0 0h32v32H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            clipPath="url(#prefix__clip0_39483_538660)"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M22 13l-6 3V9M16 28a12 12 0 1112-12M20 28c.459-1.725 2.075-3 4-3 1.925 0 3.541 1.275 4 3" />
            <path d="M24 23a3 3 0 100-6 3 3 0 000 6z" />
          </g>
          <defs>
            <clipPath id="prefix__clip0_39483_538660">
              <path fill="currentColor" d="M0 0h32v32H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            clipPath="url(#prefix__clip0_39483_538662)"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M22 13l-6 3V9M16 28a12 12 0 1112-12M20 28c.459-1.725 2.075-3 4-3 1.925 0 3.541 1.275 4 3" />
            <path d="M24 25a3 3 0 100-6 3 3 0 000 6z" />
          </g>
          <defs>
            <clipPath id="prefix__clip0_39483_538662">
              <path fill="currentColor" d="M0 0h32v32H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};

export default SendToOnboarding;
