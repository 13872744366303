import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

import { styled } from '../../theme';

export const StyledTooltip = styled(
  ({ className, children, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }}>
      {children}
    </Tooltip>
  ),
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '13px',
    fontWeight: 400,
    fontFamily: theme?.font.main,
    backgroundColor: theme?.color.bg.primary,
    borderRadius: '4px',
    color: theme?.color.typo.promo,
    padding: '16px',
    filter:
      'drop-shadow(0px 24px 32px rgba(0, 0, 0, 0.04)) drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.04)) drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04))',
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&::before': {
      backgroundColor: theme?.color.bg.primary,
    },
  },
}));

export const StyledTooltipTextWrapper = styled('div')(() => ({
  display: 'block',
}));
