import cn from 'classnames';
import { Link } from 'react-router-dom';
import { TableProps, ICellValue } from 'react-ui-kit-exante';

import { INITIAL_URL } from 'constants/routes';
import { minColumnWidth as minWidth } from 'constants/tables';
import { Position, Trade } from 'services/emir';
import { ColumnsWithFilters } from 'types/tables';

const successStatuses = ['entry_created', 'entry created'];
export function getPositionsTableColumns({
  onFilter,
  onRemove,
}: ColumnsWithFilters) {
  const columns: TableProps<Position>['columns'] = [
    {
      Header: 'Id',
      accessor: 'id',
      minWidth,
      Cell: ({ row }: ICellValue<Trade>) => (
        <Link
          to={`${INITIAL_URL}/emir/position/${row.values.id}`}
          className={cn({
            disabled: !successStatuses.includes(row.original.status ?? ''),
          })}
          target="_blank"
        >
          {row.values.id}
        </Link>
      ),
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Account',
      accessor: 'account',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Ccy',
      accessor: 'ccy',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Cp',
      accessor: 'cp',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Status',
      accessor: 'status',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Created date',
      accessor: 'created_date',
      minWidth,
      type: 'dateRange',
      onFilter,
      onRemove,
    },
    {
      Header: 'Description',
      accessor: 'description',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Error',
      accessor: 'error',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Exchange',
      accessor: 'exchange',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Expiration date',
      accessor: 'expiration_date',
      minWidth,
      type: 'dateRange',
      onFilter,
      onRemove,
    },
    {
      Header: 'Internal status',
      accessor: 'internal_status',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Isin',
      accessor: 'isin',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Operation type',
      accessor: 'operation_type',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Pnl',
      accessor: 'pnl',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Price',
      accessor: 'price',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Qty',
      accessor: 'qty',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Realized pnl',
      accessor: 'realized_pnl',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Sent date',
      accessor: 'sent_date',
      minWidth,
      type: 'dateRange',
      onFilter,
      onRemove,
    },
    {
      Header: 'Status date',
      accessor: 'status_date',
      minWidth,
      type: 'dateRange',
      onFilter,
      onRemove,
    },
    {
      Header: 'Symbol',
      accessor: 'symbol',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Value',
      accessor: 'value',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
  ];
  return columns;
}
