import { FC } from 'react';
import { Table, IColumn, IconButton } from 'react-ui-kit-exante';

import { defaultOrdering, pageSizes } from 'constants/tables';
import { OffsetAfter } from 'services/offsets';

const columns: IColumn<OffsetAfter>[] = [
  {
    Header: 'account id',
    accessor: 'account_id',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'symbol id',
    accessor: 'symbol_id',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'counterparty',
    accessor: 'counterparty',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'counterparty account id',
    accessor: 'counterparty_account_id',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'legal entity',
    accessor: 'legal_entity',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'report date',
    accessor: 'report_date',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'position after',
    accessor: 'position_after',
    disableFilters: true,
    disableSortBy: true,
  },
];

interface Props {
  offsetAfters: OffsetAfter[];
  symbolId: string;
  onClose: VoidFunction;
  isLoading: boolean;
}
export const AftersTable: FC<Props> = ({
  offsetAfters,
  symbolId,
  onClose,
  isLoading,
}) => (
  <Table
    tableId="offsetAftersTable"
    title={`Offset afters for ${symbolId}`}
    additionalActions={[
      {
        title: 'Close table',
        component: (
          <IconButton
            onClick={onClose}
            iconName="CloseIcon"
            iconColor="action"
            label="Close table"
          />
        ),
      },
    ]}
    isNotSaveVisibleColumns
    data={offsetAfters}
    columns={columns}
    defaultSortBy={defaultOrdering}
    isLoading={isLoading}
    showScrollbar
    hasPagination
    showTableInfo
    manualSortBy
    isFlexLayout
    pageSize={10}
    pageSizes={pageSizes}
  />
);
