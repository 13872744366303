import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const ClearAll = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14 28a11.9 11.9 0 01-3.625-6M24 19c0 3.958 1.664 7.375 5 9H7.625A12.956 12.956 0 014 19c0-3.527 1.404-6.362 3.684-8.705a1 1 0 011.084-.227l3.172 1.285a2 2 0 002.603-1.125l2.625-6.638c.518-1.25 1.933-1.915 3.203-1.442a2.5 2.5 0 011.439 3.3l-2.669 6.638a2 2 0 001.117 2.595l3.117 1.23a1 1 0 01.625.929V19zM5.491 13.196L24.1 20.64"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M12 28a11.9 11.9 0 01-3.625-6M22 19c0 3.958 1.664 7.375 5 9H5.625A12.956 12.956 0 012 19c0-3.527 1.404-6.362 3.684-8.705a1 1 0 011.084-.227l3.172 1.285a2 2 0 002.603-1.125l2.625-6.638c.518-1.25 1.933-1.915 3.203-1.442a2.5 2.5 0 011.439 3.3l-2.669 6.638a2 2 0 001.117 2.595l3.117 1.23a1 1 0 01.625.929V19zM3.491 13.196L22.1 20.64"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M12 28a11.9 11.9 0 01-3.625-6M22 19c0 3.958 1.664 7.375 5 9H5.625A12.956 12.956 0 012 19c0-3.527 1.404-6.362 3.684-8.705a1 1 0 011.084-.227l3.172 1.285a2 2 0 002.603-1.125l2.625-6.638c.518-1.25 1.933-1.915 3.203-1.442a2.5 2.5 0 011.439 3.3l-2.669 6.638a2 2 0 001.117 2.595l3.117 1.23a1 1 0 01.625.929V19zM3.491 13.196L22.1 20.64"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g
            opacity={0.4}
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M14 28a11.9 11.9 0 01-3.625-6M24 19c0 3.958 1.664 7.375 5 9H7.625A12.956 12.956 0 014 19c0-3.527 1.404-6.362 3.684-8.705a1 1 0 011.084-.227l3.172 1.285a2 2 0 002.603-1.125l2.625-6.638c.518-1.25 1.933-1.915 3.203-1.442a2.5 2.5 0 011.439 3.3l-2.669 6.638a2 2 0 001.117 2.595l3.117 1.23a1 1 0 01.625.929V19zM5.491 13.196L24.1 20.64" />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};

export default ClearAll;
