import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Panel,
  Table,
  CenteredLoader,
  Select,
  ICellValue,
} from 'react-ui-kit-exante';

import { MifirApiService, QuarterStatItem } from 'services/mifir';
import { getNumberSize } from 'utils';

import { quarterOptions } from './consts';

const colors: Record<string, string> = {
  green: '--text-green',
  red: '--text-red',
};
const api = new MifirApiService();
export const Dashboard: FC = () => {
  const [selectedQuarter, setSelectedQuarter] = useState(
    quarterOptions[quarterOptions.length - 1].value,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [headSections, setHeadSections] = useState<string[]>([]);
  const [entityItems, setEntityItems] = useState<string[]>([]);
  const [reportQuarterStats, setReportQuarterStats] = useState<
    QuarterStatItem[]
  >([]);
  const [data, setData] = useState<Record<string, Record<string, unknown>[]>>(
    {},
  );

  const isEmptyData = useMemo(
    () =>
      !Object.values(data).reduce((acc, item) => [...acc, ...item], []).length,
    [data],
  );
  const fetchData = async () => {
    setIsLoading(true);
    const response = await api.getReportQuarterStats({
      quarter: selectedQuarter,
    });
    setData({});
    setReportQuarterStats(response?.data ?? []);
    if (response?.data.length) {
      const { sections, itemsBySections, entities } = (
        response || []
      ).data.reduce(
        (
          acc: {
            sections: string[];
            entities: string[];
            itemsBySections: Record<string, Record<string, unknown>[]>;
          },
          item,
        ) =>
          item.dimension_3
            ? acc
            : {
                itemsBySections: {
                  ...acc.itemsBySections,
                  [item.section]: [
                    ...(acc.itemsBySections[item.section] ?? []).filter(
                      (dataItem) => dataItem.id !== item.value_1,
                    ),
                    {
                      id: item.value_1,
                      ...(acc.itemsBySections[item.section] ?? []).find(
                        (dataItem) => dataItem.id === item.value_1 ?? {},
                      ),
                      [`${item.entity}_color`]: item.color,
                      [item.entity]: getNumberSize(
                        item.value_2 ? Number(item.value_2) : undefined,
                      ),
                    },
                  ],
                },
                entities: acc.entities.includes(item.entity)
                  ? acc.entities
                  : [...acc.entities, item.entity],
                sections: acc.sections.includes(item.section)
                  ? acc.sections
                  : [...acc.sections, item.section],
              },
        { sections: [], itemsBySections: {}, entities: [] },
      );
      setEntityItems(entities);
      setHeadSections(sections);
      setData(itemsBySections);
    }
    setIsLoading(false);
  };

  const columns = useMemo(
    () => [
      { Header: '', accessor: 'id', minWidth: 140, disableSortBy: true },
      ...entityItems.map((entity) => ({
        Header: entity,
        accessor: entity,
        disableSortBy: true,
        formatting: 'number',
        tooltip: {
          type: 'custom',
          options: {
            Title: (value: string) =>
              reportQuarterStats.find(
                (item) => value === getNumberSize(Number(item.value_2)),
              )?.hint ?? '',
          },
        },
      })),
    ],
    [entityItems, reportQuarterStats],
  );

  useEffect(() => {
    fetchData();
  }, [selectedQuarter]);

  const getCellProps = useCallback(
    (cell: ICellValue<Record<string, unknown>>) => ({
      style: {
        color: `var(${
          colors[cell.row.original[`${cell.column.id}_color`] as string] ??
          'gray'
        })`,
      },
    }),
    [],
  );

  return (
    <Panel
      title="Regulatory Reporting Dashboard"
      action={
        <div style={{ width: '200px' }}>
          <Select
            fullWidth
            value={selectedQuarter}
            onChange={(event) => {
              setSelectedQuarter(event.target.value);
            }}
            placeholder="Reporting date"
            options={quarterOptions}
          />
        </div>
      }
    >
      {isLoading ? (
        <CenteredLoader />
      ) : (
        <div style={{ display: 'flex', flexWrap: 'wrap', flex: '1 1' }}>
          {isEmptyData
            ? 'No data'
            : headSections.map((section) => (
                <div style={{ width: '100%' }} key={section}>
                  <h3>{section}</h3>
                  <Table
                    tableId={section}
                    data={data[section] ?? []}
                    columns={columns}
                    getCellProps={getCellProps}
                    showTableInfo
                  />
                </div>
              ))}
        </div>
      )}
    </Panel>
  );
};
