import { AutocompleteValue } from '@mui/material';
import {
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete/Autocomplete';
import { debounce, throttle } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { IOption } from '../../../types/form';
import { Autocomplete, IAutocompleteProps } from '../Autocomplete';
import { Input } from '../Input/Input';

import { ListBox } from './ListBox';
import { ON_CHANGE_REASON, ON_INPUT_CHANGE_REASON } from './constants';
import { IAutocompleteAsyncProps } from './types';

const EMPTY_OPTIONS = [] as IOption[];
const REMAINING_SCROLL_AREA = 50;
const DEBOUNCE_DELAY = 500;
const THROTTLE_DELAY = 300;

export const AutocompleteAsync = <
  T extends IOption,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  error = false,
  fetchData: propFetchData,
  message = '',
  options: propOptions,
  placeholder,
  value: propValue,
  isMultiple,
  ...props
}: IAutocompleteAsyncProps &
  IAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const [value, setValue] = useState(propValue);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<IOption[]>(propOptions);
  const [total, setTotal] = useState(Infinity);
  const controller = useRef(new AbortController());
  const fetchData = useRef(propFetchData);
  const typeOfOptionsInsert = useRef<'PUSH' | 'NEW' | null>(null);
  const defaultEmptyValue = useMemo(
    () =>
      (isMultiple ? [] : null) as AutocompleteValue<
        T,
        Multiple,
        DisableClearable,
        FreeSolo
      >,
    [isMultiple],
  );

  const getOptions = useCallback(
    (search: string) => {
      controller.current.abort();
      controller.current = new AbortController();

      setIsLoading(true);

      if (fetchData.current) {
        fetchData
          .current(search, {
            signal: controller.current.signal,
          })
          .then(
            ({
              options: optionsThen,
              fetchData: fetchDataThen,
              total: totalThen,
            }) => {
              if (fetchDataThen) {
                fetchData.current = fetchDataThen;
              }

              if (typeOfOptionsInsert.current === 'PUSH') {
                setOptions((prevOptions) => [...prevOptions, ...optionsThen]);
              } else {
                setOptions(optionsThen);
              }

              setIsLoading(false);
              setTotal(totalThen);
            },
          )
          .catch((e) => {
            if (e instanceof Error && e.name !== 'AbortError') {
              setIsLoading(false);
            }
          });
      }
    },
    [fetchData],
  );

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => {
      return (
        <Input
          error={error}
          helperText={message}
          placeholder={placeholder}
          {...params}
        />
      );
    },
    [error, message, placeholder],
  );

  const scrollHandler = useCallback(
    (event: any) => {
      if (
        !isLoading &&
        event.target.clientHeight + event.target.scrollTop >
          event.target.scrollHeight - REMAINING_SCROLL_AREA &&
        options.length < total
      ) {
        typeOfOptionsInsert.current = 'PUSH';
        getOptions(inputValue);
      }
    },
    [getOptions, isLoading, inputValue, total, options.length],
  );

  const debounceGetOptionsOnInputChange = useMemo(
    () =>
      debounce((search) => {
        fetchData.current = propFetchData;
        getOptions(search);
      }, DEBOUNCE_DELAY),
    [propFetchData, getOptions],
  );

  const throttleScrollHandler = useMemo(
    () => throttle(scrollHandler, THROTTLE_DELAY),
    [scrollHandler],
  );

  const handleChange = useCallback(
    (_: any, newValue: any, reason: string) => {
      if (
        reason === ON_CHANGE_REASON.SELECT_OPTION &&
        typeof newValue !== 'string'
      ) {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        setInputValue(newValue.label);
      }

      if (reason === ON_CHANGE_REASON.CLEAR) {
        setOptions(EMPTY_OPTIONS);
        setValue(defaultEmptyValue);
        setInputValue('');
      }
    },
    [defaultEmptyValue, options],
  );

  const handleClose = useCallback(() => {
    if (isLoading) {
      controller.current.abort();
      controller.current = new AbortController();

      setIsLoading(false);
    }

    setOptions(EMPTY_OPTIONS);
  }, [isLoading]);

  const handleInputChange = useCallback(
    (
      _: React.SyntheticEvent,
      newInputValue: string,
      reason: AutocompleteInputChangeReason,
    ) => {
      if (reason === ON_INPUT_CHANGE_REASON.INPUT) {
        setInputValue(newInputValue);

        if (value !== newInputValue) {
          typeOfOptionsInsert.current = 'NEW';
          debounceGetOptionsOnInputChange(newInputValue.trim());
        }
      }

      if (reason === ON_INPUT_CHANGE_REASON.CLEAR) {
        setOptions(EMPTY_OPTIONS);
        setValue(defaultEmptyValue);
        setInputValue('');
      }
    },
    [value, debounceGetOptionsOnInputChange, defaultEmptyValue],
  );

  const handleOpen = useCallback(() => {
    fetchData.current = propFetchData;

    typeOfOptionsInsert.current = 'NEW';

    // TODO sort out the value types
    const request =
      typeof value === 'string' ? value : (value as IOption)?.value || '';
    getOptions(request || '');
  }, [propFetchData, value, getOptions]);

  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  return (
    <Autocomplete
      autoComplete
      filterOptions={(x) => x}
      filterSelectedOptions
      ListboxComponent={ListBox}
      ListboxProps={{
        loading: isLoading,
        onScroll: throttleScrollHandler,
      }}
      loading={isLoading}
      onChange={handleChange}
      onClose={handleClose}
      onInputChange={handleInputChange}
      onOpen={handleOpen}
      options={options as T[]}
      renderInput={renderInput}
      value={value}
      isMultiple={isMultiple}
      {...props}
    />
  );
};
