import {
  ICellValue,
  IColumn,
  ISelectOption,
  styled,
  CircleCloseIcon,
  CircleCheckIcon,
} from 'react-ui-kit-exante';

import {
  Offset,
  Adjustment,
  ActivityReport,
  Statuses,
  Discrepancy,
} from 'services/offsets';
import { ColumnsWithFilters } from 'types';
import { getFirstUpperName } from 'utils';

const statusColor = {
  [Statuses.PROCESSED]: 'source',
  [Statuses.NOT_SENT]: 'radical',
  [Statuses.ACTIVE]: 'secondary',
};
const StatusDiv = styled('div')<{ status: Statuses }>(({ status, theme }) => ({
  color: theme.color.typo[statusColor[status]],
  display: 'flex',
  gap: '4px',
}));
const getStatusIcon = (status: Statuses) => {
  if (status === Statuses.NOT_SENT) {
    return <CircleCloseIcon />;
  }
  return <CircleCheckIcon />;
};
export const getOffsetsColumns = ({
  onRemove,
  onFilter,
}: ColumnsWithFilters) => {
  const columns: IColumn<Offset>[] = [
    {
      Header: 'Trade Date',
      accessor: 'report_date',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Symbol',
      accessor: 'symbol_id',
      onFilter,
      onRemove,
      Cell: ({ row }: ICellValue<Offset>) => (
        <span style={{ whiteSpace: 'pre-wrap' }}>{row.values.symbol_id}</span>
      ),
      disableSortBy: true,
    },
    {
      Header: 'Account',
      accessor: 'account_id',
      onFilter,
      onRemove,
      Cell: ({ row }: ICellValue<Offset>) => (
        <span style={{ whiteSpace: 'pre-wrap' }}>{row.values.account_id}</span>
      ),
      disableSortBy: true,
    },
    {
      Header: 'Counterparty',
      accessor: 'counterparty',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'CP account',
      accessor: 'counterparty_account_id',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'before',
      accessor: 'position_before',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'bought',
      accessor: 'bought',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'sold',
      accessor: 'sold',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'offset',
      accessor: 'offset',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'after',
      accessor: 'position_after',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'GS quantity',
      accessor: 'gs_quantity',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'diff with after and gs',
      accessor: 'position_after_gs_diff',
      disableFilters: true,
      width: 200,
      disableSortBy: true,
    },
  ];
  return columns;
};

interface ColumnsPayload extends ColumnsWithFilters {
  cpOptions: ISelectOption[];
  leOptions: ISelectOption[];
  cpAccountOptions: ISelectOption[];
}
export const getAdjustmentColumns = ({
  cpOptions,
  leOptions,
  cpAccountOptions,
  onFilter,
  onRemove,
}: ColumnsPayload) => {
  const columns: IColumn<Adjustment>[] = [
    {
      Header: 'Trade Date',
      accessor: 'report_date',
      type: 'dateRange',
      editParams: {
        editable: () => true,
        inputType: 'date',
      },
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Symbol',
      accessor: 'symbol_id',
      editParams: {
        editable: () => true,
        inputType: 'text',
      },
      onFilter,
      onRemove,
      disableSortBy: true,
    },
    {
      Header: 'Account',
      accessor: 'account_id',
      editParams: {
        editable: () => true,
        inputType: 'text',
      },
      disableSortBy: true,
      onFilter,
      onRemove,
    },
    {
      Header: 'Counterparty',
      accessor: 'counterparty',
      editParams: {
        editable: () => true,
        inputType: 'select',
        options: cpOptions,
      },
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Legal entity',
      accessor: 'legal_entity',
      editParams: {
        editable: () => true,
        inputType: 'select',
        options: leOptions,
      },
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'CP account',
      accessor: 'counterparty_account_id',
      editParams: {
        editable: () => true,
        inputType: 'select',
        options: cpAccountOptions,
      },
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Bought',
      accessor: 'bought',
      editParams: {
        editable: () => true,
        inputType: 'text',
      },
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Sold',
      accessor: 'sold',
      editParams: {
        editable: () => true,
        inputType: 'text',
      },
      disableFilters: true,
      disableSortBy: true,
    },
  ];
  return columns;
};

export const getActivityColumns = () => {
  const columns: IColumn<ActivityReport>[] = [
    {
      Header: 'Date',
      accessor: 'report_date',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Counterparty',
      accessor: 'counterparty',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row }: ICellValue<ActivityReport>) => {
        return (
          <StatusDiv status={row.original.status}>
            {getStatusIcon(row.original.status)}{' '}
            {getFirstUpperName(row.original.status)}
          </StatusDiv>
        );
      },
      disableFilters: true,
      disableSortBy: true,
    },
  ];
  return columns;
};

export const getDiscrepanciesColumns = ({
  onRemove,
  onFilter,
}: ColumnsWithFilters) => {
  const columns: IColumn<Discrepancy>[] = [
    {
      Header: 'Symbol',
      accessor: 'symbol_id',
      onFilter,
      onRemove,
      disableSortBy: true,
    },
    {
      Header: 'CP account',
      accessor: 'counterparty_account_id',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'CP QTY',
      accessor: 'cp_qty_grouped',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'After',
      accessor: 'position_after_grouped',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'GS QTY',
      accessor: 'gs_quantity_grouped',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'NET Poss',
      accessor: 'gs_quantity_net',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'DIFFERENCE',
      accessor: 'cp_gs_diff',
      disableFilters: true,
      disableSortBy: true,
    },
  ];
  return columns;
};
