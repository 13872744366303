export interface Trade {
  uid: string;
  account: string;
  trade_date: string;
  trade_time: string;
  operation_type: string;
  value_date: string;
  side: string;
  symbol: string;
  symbol_type: string;
  isin: string | null;
  qty: string;
  price: string;
  value: string;
  ccy: string;
  cp_account: string;
  execution_cp: string;
  settlement_cp: string;
  entity: string;
  order_id: string;
  position_id: number;
  sent_date: string | null;
  status: string | null;
  status_date: string | null;
  timestamp: string;
  error: string;
  internal_status: string;
  bo_trade_detailed: string | null;
  chain_id: string;
}

export interface TradesTable {
  trades: Trade[];
  pagination: { total: number };
}

export interface Position {
  account: string;
  ccy: string;
  cp?: string;
  created_date: string;
  description: string;
  error?: string;
  exchange: string;
  expiration_date: string;
  id: number;
  internal_status: string;
  isin?: string;
  operation_type: string;
  pnl: string;
  price: string;
  qty: string;
  realized_pnl?: string;
  sent_date?: string;
  status?: string;
  status_date?: string;
  symbol: string;
  value: string;
}

export interface PositionsTable {
  positions: Position[];
  pagination: { total: number };
}

export interface DetailInfoItem {
  name: string;
  items: Record<string, string>;
}

export enum FilterValueType {
  STRING = 'string',
  FLOAT = 'float',
  DATE = 'date',
  BOOLEAN = 'bool',
}

interface ScriptParamItem {
  type: FilterValueType;
  choices?: string[];
}

export interface ScriptItem {
  name: string;
  params: Record<string, ScriptParamItem>;
}

export enum STATUSES {
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  RUNNING = 'RUNNING',
  FINISHED = 'FINISHED',
}
export interface Metrics {
  positions: Record<string, number>;
  trades: Record<string, number>;
}
export interface Task {
  end_time: string | null;
  id: number;
  name: string;
  output: string;
  start_time: string;
  report_date: string;
  metrics: Metrics;
  status: STATUSES;
}

export interface TasksTable {
  tasks: Task[];
  pagination: { total: number };
}

export enum Urls {
  TRADES = 'trades?',
  POSITIONS = 'positions?',
  TASKS = 'tasks?',
}
