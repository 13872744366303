import { IOnFetchArguments, Notification } from 'react-ui-kit-exante';

import {
  getUrlFromPayload,
  getSortingParams,
  ResponseWithPagination,
} from '../api';
import BaseApiService from '../api/Api.service';

import { getMifidEndpoint, Urls } from './mifir.consts';
import {
  Report,
  Entry,
  SftrTrade,
  SftrReport,
  FetchQuarterStatsPayload,
  FetchQuarterStatsResponse,
} from './mifir.types';

export class MifirApiService extends BaseApiService {
  _apiBase = getMifidEndpoint();

  async getReports({ params }: IOnFetchArguments) {
    try {
      const { sorting, skip, ...filterParams } = params;
      const response = await this.getResource<ResponseWithPagination<Report>>(
        getUrlFromPayload(`${Urls.REPORTS}?`, filterParams) +
          getSortingParams(sorting),
      );
      return {
        reports: response.content,
        pagination: response.pagination as any,
      };
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async getReportEntries({ params }: IOnFetchArguments, id: string) {
    try {
      const { sorting, skip, ...filterParams } = params;
      const response = await this.getResource<ResponseWithPagination<Entry>>(
        getUrlFromPayload(`${Urls.REPORTS}/${id}?`, filterParams) +
          getSortingParams(sorting),
      );
      return {
        entries: response.content,
        pagination: response.pagination as any,
      };
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async getEntry(id: string) {
    try {
      return await this.getResource<{ entry: Entry; report: Report }>(
        `${Urls.ENTRY}/${id}`,
      );
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async getSftrTrades({ params }: IOnFetchArguments) {
    try {
      const { sorting, skip, ...filterParams } = params;
      const response = await this.getResource<
        ResponseWithPagination<SftrTrade>
      >(
        getUrlFromPayload(`${Urls.SFTR_TRADES}?`, filterParams) +
          getSortingParams(sorting),
      );
      return {
        trades: response.content,
        pagination: response.pagination as any,
      };
    } catch (error) {
      Notification.error({
        title: 'Get Sftr trades error:',
        description: JSON.stringify(error),
      });
    }
    return {
      trades: [],
      pagination: {},
    };
  }

  async getSftrReports({ params }: IOnFetchArguments) {
    try {
      const { sorting, skip, ...filterParams } = params;
      const response = await this.getResource<
        ResponseWithPagination<SftrReport>
      >(
        getUrlFromPayload(`${Urls.SFTR_REPORTS}?`, filterParams) +
          getSortingParams(sorting),
      );
      return {
        reports: response.content,
        pagination: response.pagination as any,
      };
    } catch (error) {
      Notification.error({
        title: 'Get Sftr reports error:',
        description: JSON.stringify(error),
      });
    }
    return {
      reports: [],
      pagination: {},
    };
  }

  async getReportQuarterStats(params: FetchQuarterStatsPayload) {
    try {
      return await this.getResource<FetchQuarterStatsResponse>(
        getUrlFromPayload(`${Urls.REPORT_QUARTER_STATS}?`, params),
      );
    } catch (error) {
      Notification.error({
        title: 'Load quarter stats error:',
        description: JSON.stringify(error),
      });
    }
    return { data: [] };
  }
}
