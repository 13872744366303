import { IOnFetchArguments, Notification } from 'react-ui-kit-exante';

import { Response } from 'types';

import { getUrlFromPayload } from '../api';
import BaseApiService from '../api/Api.service';

import { getOffsetsEndpoint, Urls } from './consts';
import {
  ActivityReport,
  Adjustment,
  Discrepancy,
  Offset,
  OffsetAfter,
} from './types';

export interface AddAdjustmentPayload {
  report_date: string;
  symbol_id: string;
  account_id: string;
  legal_entity: string;
  counterparty: string;
  counterparty_account_id: string;
  sold: string;
  bought: string;
}

export class OffsetsApiService extends BaseApiService {
  _apiBase = getOffsetsEndpoint();

  async getOffsets({ params, filtersParams }: IOnFetchArguments) {
    const { sorting, skip, page, limit, ...otherParams } = params;
    try {
      const response = await this.getResource<Response<Offset>>(
        getUrlFromPayload(`${Urls.OFFSETS}?`, {
          ...otherParams,
          ...filtersParams,
          offset: page * limit,
          limit,
        }),
      );
      return {
        items: response.items,
        pagination: {
          total: response.total,
        },
      };
    } catch (error) {
      Notification.error({
        title: 'Get offsets error:',
        description: JSON.stringify(error),
      });
      return {
        items: [],
        pagination: {},
      };
    }
  }

  async getOffsetAfters({
    symbolId,
    reportDate,
  }: {
    symbolId: string;
    reportDate: string;
  }) {
    try {
      return await this.getResource<OffsetAfter[]>(
        getUrlFromPayload(`${Urls.OFFSET_AFTERS}?`, {
          symbol_id: symbolId,
          report_date: reportDate,
        }),
      );
    } catch (error) {
      Notification.error({
        title: 'Get offset afters error:',
        description: JSON.stringify(error),
      });
      return [];
    }
  }

  async getAdjustments({ params, filtersParams }: IOnFetchArguments) {
    try {
      const { sorting, skip, page, limit, ...otherParams } = params;
      const response = await this.getResource<Response<Adjustment>>(
        getUrlFromPayload(`${Urls.ADJUSTMENTS}?`, {
          ...otherParams,
          ...filtersParams,
          offset: page * limit,
          limit,
        }),
      );
      return {
        items: response.items,
        pagination: {
          total: response.total,
        },
      };
    } catch (error) {
      Notification.error({
        title: 'Get adjustments error:',
        description: JSON.stringify(error),
      });
      return {
        items: [],
        pagination: {},
      };
    }
  }

  async getCpList() {
    try {
      return await this.getResource<string[]>(
        getUrlFromPayload(`${Urls.CP_LIST}?`, {}),
      );
    } catch (error) {
      Notification.error({
        title: 'Get cp list error:',
        description: JSON.stringify(error),
      });
      return [];
    }
  }

  async getCpAccountList() {
    try {
      return await this.getResource<string[]>(
        getUrlFromPayload(`${Urls.CP_ACCOUNT_LIST}?`, {}),
      );
    } catch (error) {
      Notification.error({
        title: 'Get cp account list error:',
        description: JSON.stringify(error),
      });
      return [];
    }
  }

  async getLeList() {
    try {
      return await this.getResource<string[]>(
        getUrlFromPayload(`${Urls.LE_LIST}?`, {}),
      );
    } catch (error) {
      Notification.error({
        title: 'Get legal entity list error:',
        description: String(error),
      });
      return [];
    }
  }

  async addAdjustment(payload: AddAdjustmentPayload) {
    return this.postResource(Urls.ADJUSTMENTS, payload);
  }

  async deleteAdjustment(id: number) {
    return this.delete(`${Urls.ADJUSTMENTS}/${id}`);
  }

  async updateAdjustment(
    id: number,
    payload: Omit<AddAdjustmentPayload, 'legal_entity'>,
  ) {
    return this.put(`${Urls.ADJUSTMENTS}/${id}`, payload);
  }

  async uploadAdjustments(files: FileList) {
    const formData = new FormData();
    formData.append('file', files[0]);
    return this.postFormData(`${Urls.ADJUSTMENTS}/upload`, formData);
  }

  async getActivity({ params, filtersParams }: IOnFetchArguments) {
    const { sorting, skip, page, limit, ...otherParams } = params;
    try {
      const response = await this.getResource<Response<ActivityReport>>(
        getUrlFromPayload(`${Urls.REPORTS}?`, {
          ...otherParams,
          ...filtersParams,
          offset: page * limit,
          limit,
        }),
      );
      return {
        items: response.items,
        pagination: {
          total: response.total,
        },
      };
    } catch (error) {
      Notification.error({
        title: 'Get offsets error:',
        description: String(error),
      });
      return {
        items: [],
        pagination: {},
      };
    }
  }

  async getDiscrepancies({ params, filtersParams }: IOnFetchArguments) {
    const { sorting, skip, page, limit, ...otherParams } = params;
    try {
      const response = await this.getResource<Response<Discrepancy>>(
        getUrlFromPayload(`${Urls.DISCREPANCIES}?`, {
          ...otherParams,
          ...filtersParams,
          offset: page * limit,
          limit,
        }),
      );
      return {
        items: response.items,
        pagination: {
          total: response.total,
        },
      };
    } catch (error) {
      Notification.error({
        title: 'Get discrepancies error:',
        description: String(error),
      });
      return {
        items: [],
        pagination: {},
      };
    }
  }
}
