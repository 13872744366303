import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Notification,
  Panel,
  Table,
  TableProps,
  CenteredLoader,
} from 'react-ui-kit-exante';

import { MifirApiService, Entry, XmlEntryItem } from 'services/mifir';

const api = new MifirApiService();
const columns: TableProps<XmlEntryItem>['columns'] = [
  { Header: 'Name', accessor: 'name' },
  { Header: 'Value', accessor: 'value' },
];
export const MifidEntryPage: FC = () => {
  const { entryId } = useParams<{ entryId: string }>();
  const [entry, setEntry] = useState<null | Entry>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    api
      .getEntry(String(entryId))
      .then((res) => {
        setEntry(res.entry);
      })
      .catch((error) => {
        Notification.error({
          title: 'Loading error:',
          description: JSON.stringify(error),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <CenteredLoader />;
  }
  return entry ? (
    <div className="mui-row mt-2">
      <div className="mui-col-md-12">
        <Panel title={`Entry: ${entryId}`}>
          <div className="text-left">
            <p>Bo trade: {entry?.bo_trade}</p>
            <p>Bo symbol: {entry?.bo_symbol}</p>
            <p>Report uid: {entry?.report_uid}</p>
            <p>Status: {entry?.status_id}</p>
          </div>
        </Panel>
      </div>
      <div className="mui-col-md-12 d-flex">
        <Panel title="Seller" className="w-50">
          <Table
            tableId="entry_table_seller"
            data={entry.xml_entry_str.seller}
            columns={columns}
          />
        </Panel>
        <Panel title="Buyer" className="w-50">
          <Table
            tableId="entry_table_buyer"
            data={entry.xml_entry_str.buyer}
            columns={columns}
          />
        </Panel>
      </div>
      <div className="mui-col-md-12">
        <Panel title="Common">
          <Table
            tableId="entry_table_common"
            isFlexLayout
            data={entry.xml_entry_str.common}
            columns={columns}
          />
        </Panel>
      </div>
    </div>
  ) : null;
};
