import { TCommonParams } from 'react-ui-kit-exante';

import { SortingTableObject } from './Api.types';

export const getApiUrl = (url: string, isHaveSsl = true) =>
  `${isHaveSsl ? 'https' : 'http'}://${url}/api/v1/`;

export function getUrlFromPayload(url: string, payload: Record<string, any>) {
  return Object.keys(payload).reduce((acc, key) => {
    let value = payload[key];
    if (value === '' || value === undefined) {
      return acc;
    }
    if (typeof value === 'string' && value.includes('&')) {
      value = value.replace('&', '%26');
    }
    return `${acc}${key}=${value}&`;
  }, url);
}

export const getSortingParams = (sorting: TCommonParams['sorting']) =>
  (sorting as SortingTableObject[]).reduce((acc, item) => {
    const noEmpty = !!Object.values(item).length;
    const direction = item.desc ? 'desc' : 'asc';
    return noEmpty ? `${acc}order=${direction}&order_by=${item.id}&` : acc;
  }, '');
