import { merge } from 'lodash';
import { FC, ReactNode, useMemo } from 'react';
import { ThemeProvider, createTheme } from 'react-ui-kit-exante';

import { theme } from './index';

export const ComposedThemeProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const brandingTheme = window.REG_REPORTING_UI?.theme;

  const themeValue = useMemo(() => {
    if (!brandingTheme) {
      return theme;
    }

    return createTheme(merge({}, theme, brandingTheme));
  }, [brandingTheme]);

  return <ThemeProvider theme={themeValue}>{children}</ThemeProvider>;
};
