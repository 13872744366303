import { IOnFetchArguments, Notification } from 'react-ui-kit-exante';

import {
  getSortingParams,
  getUrlFromPayload,
  ResponseWithPagination,
} from '../api';
import BaseApiService from '../api/Api.service';

import { getEmirEndpoint } from './emir.consts';
import {
  DetailInfoItem,
  Position,
  ScriptItem,
  Task,
  Trade,
  Urls,
} from './emir.types';

export class EmirApiService extends BaseApiService {
  _apiBase = getEmirEndpoint();

  async getTrades({ params }: IOnFetchArguments) {
    try {
      const { sorting, skip, ...filterParams } = params;
      const response = await this.getResource<ResponseWithPagination<Trade>>(
        getUrlFromPayload(Urls.TRADES, filterParams) +
          getSortingParams(params.sorting),
      );
      return {
        trades: response.content,
        pagination: response.pagination as any,
      };
    } catch (error) {
      Notification.error({
        title: 'Load trades error:',
        description: JSON.stringify(error),
      });
    }
    return {
      trades: [],
      pagination: {},
    };
  }

  async getCurrentTrade(tradeId: string) {
    return this.getResource<Record<string, DetailInfoItem[]>>(
      `trades/entries?trade=${tradeId}`,
    );
  }

  async getPositions({ params }: IOnFetchArguments) {
    try {
      const { sorting, skip, ...filterParams } = params;
      const response = await this.getResource<ResponseWithPagination<Position>>(
        getUrlFromPayload(Urls.POSITIONS, filterParams) +
          getSortingParams(sorting),
      );
      return {
        positions: response.content,
        pagination: response.pagination as any,
      };
    } catch (error) {
      Notification.error({
        title: 'Load positions error:',
        description: JSON.stringify(error),
      });
    }
    return {
      positions: [],
      pagination: {},
    };
  }

  async getCurrentPosition(positionId: string) {
    return this.getResource<Record<string, DetailInfoItem[]>>(
      `positions/entries?position=${positionId}`,
    );
  }

  async getScripts() {
    return this.getResource<{ scripts: ScriptItem[] }>(`get_run_scripts`);
  }

  async runScript(payload: Record<string, boolean | string>) {
    return this.postResource(`run`, payload);
  }

  async getTasks({ params }: IOnFetchArguments) {
    try {
      const { sorting, ...filterParams } = params;
      const response = await this.getResource<ResponseWithPagination<Task>>(
        getUrlFromPayload(Urls.TASKS, filterParams) + getSortingParams(sorting),
      );
      return {
        tasks: response.content,
        pagination: response.pagination as any,
      };
    } catch (error) {
      Notification.error({
        title: 'Load tasks error:',
        description: JSON.stringify(error),
      });
    }
    return {
      tasks: [],
      pagination: {},
    };
  }
}
