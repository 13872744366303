import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { Panel, Tabs, Tab, Select, styled } from 'react-ui-kit-exante';

import { StockLendingApiService } from 'services/stock-lending';
import { getSelectOptions } from 'utils';

import { HistoryTable } from './HistoryTable';
import { OtherTables } from './OtherTables';

export const ActionsDiv = styled('div')(() => ({
  width: '240px',
  marginRight: '20px',
}));

const api = new StockLendingApiService();

export const StockLending: FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [counterparty, setCounterparty] = useState<string>('');

  const [counterpartyList, setCounterpartyList] = useState<string[]>([]);

  const fetchCounterparties = async () => {
    const response = await api.getCounterpartiesList();
    if (response[0]) {
      setCounterparty(response[0].name);
    }
    setCounterpartyList(response.map((item) => item.name));
  };

  useEffect(() => {
    fetchCounterparties();
  }, []);

  const cpOptions = useMemo(
    () => getSelectOptions(counterpartyList),
    [counterpartyList],
  );
  const handleChange = (_: ChangeEvent<unknown>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleSetCounterparty = (event: ChangeEvent<HTMLInputElement>) => {
    setCounterparty(event.target.value);
  };

  const tableTitle = (
    <ActionsDiv>
      <Select
        label="Counterparty"
        fullWidth
        value={counterparty}
        onChange={handleSetCounterparty}
        options={cpOptions}
      />
    </ActionsDiv>
  );

  return (
    <div className="mui-row mt-2">
      <div className="mui-col-md-12">
        <Panel title="Security Lending" className="pb-0">
          <Tabs value={selectedTab} onChange={handleChange}>
            <Tab label="Available Securities" />
            <Tab label="Recall" />
            <Tab label="History" />
          </Tabs>
        </Panel>
        {selectedTab === 2 ? (
          <HistoryTable tableTitle={tableTitle} counterparty={counterparty} />
        ) : (
          <OtherTables
            tableTitle={tableTitle}
            counterparty={counterparty}
            selectedTab={selectedTab}
          />
        )}
      </div>
    </div>
  );
};
