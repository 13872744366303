import { useState } from 'react';
import { TableProps, Button, ICellValue } from 'react-ui-kit-exante';

import { minColumnWidth as minWidth } from 'constants/tables';
import { STATUSES, Task } from 'services/emir';
import { ColumnsWithFilters } from 'types/tables';

import { Metrics } from './Metrics';

export const getTasksTableHeader = ({
  onFilter,
  onRemove,
}: ColumnsWithFilters) => {
  const columns: TableProps<Task>['columns'] = [
    {
      Header: 'Id',
      accessor: 'id',
      minWidth: 70,
      width: 70,
      disableFilters: true,
    },
    {
      Header: 'Name',
      accessor: 'name',
      minWidth: 110,
      width: 110,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Start time',
      accessor: 'start_time',
      minWidth,
      disableFilters: true,
    },
    {
      Header: 'End time',
      accessor: 'end_time',
      minWidth,
      disableFilters: true,
    },
    {
      Header: 'Report date',
      accessor: 'report_date',
      minWidth,
      type: 'dateRange',
      onFilter,
      onRemove,
    },
    {
      Header: 'Output',
      accessor: 'output',
      minWidth,
      maxWidth: 3000,
      disableFilters: true,
    },
    {
      Header: 'Metrics',
      accessor: 'metrics',
      minWidth: 160,
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }: ICellValue<Task>) => {
        const [isShow, setIsShow] = useState(false);
        const handleChangeShow = () => setIsShow(!isShow);
        return (
          <div className="text-left">
            <Button size="small" onClick={handleChangeShow}>
              {isShow ? 'hide' : 'show'}
            </Button>
            {isShow ? (
              <Metrics
                metrics={row.original.metrics}
                reportDate={row.original.report_date}
              />
            ) : null}
          </div>
        );
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      minWidth: 120,
      type: 'select',
      filterOptions: [
        { label: STATUSES.CREATED, value: STATUSES.CREATED },
        { label: STATUSES.FAILED, value: STATUSES.FAILED },
        { label: STATUSES.RUNNING, value: STATUSES.RUNNING },
        { label: STATUSES.FINISHED, value: STATUSES.FINISHED },
      ],
      onFilter,
      onRemove,
    },
  ];
  return columns;
};
