import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

import { getSelectOptions } from 'utils';

dayjs.extend(quarterOfYear);

const today = dayjs();
const quarters: string[] = [];

const firstQuarterStartDate = dayjs('2021-07-01');
let count = today.diff(firstQuarterStartDate, 'month');

while (count >= 0) {
  const date = today.subtract(count, 'month');
  quarters.push(`Q${date.quarter()}-${date.year()}`);
  count -= 3;
}
export const quarterOptions = getSelectOptions(quarters);
