import { Link } from 'react-router-dom';
import { IColumn, ICellValue } from 'react-ui-kit-exante';

import { INITIAL_URL } from 'constants/routes';
import { Report } from 'services/mifir';
import { ColumnsWithFilters } from 'types/tables';

export const getReportsTableColumns = ({
  onFilter,
  onRemove,
}: ColumnsWithFilters): IColumn<Report>[] => [
  {
    Header: 'Date',
    accessor: 'date',
    type: 'dateRange',
    onFilter,
    onRemove,
  },
  {
    Header: 'Legal Entity',
    accessor: 'legal_entity_id',
    type: 'textInput',
    onFilter,
    onRemove,
  },
  {
    Header: 'Status',
    accessor: 'status_id',
    type: 'textInput',
    onFilter,
    onRemove,
  },
  {
    Header: 'UID',
    accessor: 'uid',
    type: 'textInput',
    Cell: ({ row }: ICellValue<Report>) => (
      <Link to={`${INITIAL_URL}/mifid/reports/${row.values.uid}`}>
        {row.values.uid}
      </Link>
    ),
    onFilter,
    onRemove,
  },
];
