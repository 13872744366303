export const MINIMUM_FRACTION_DIGITS = 2;
export function getNumberSize(number: number | undefined): string {
  const absoluteNumber = number ? Math.abs(number) : undefined;
  if (absoluteNumber === undefined || absoluteNumber.toString() === '0') {
    return '';
  }
  if (absoluteNumber >= 1000) {
    return `${Number(
      (absoluteNumber / 1000).toFixed(MINIMUM_FRACTION_DIGITS),
    )} K`;
  }
  return `${number}`;
}
