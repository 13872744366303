import { Link } from 'react-router-dom';
import { IColumn, ICellValue } from 'react-ui-kit-exante';

import { INITIAL_URL } from 'constants/routes';
import { Entry } from 'services/mifir';
import { ColumnsWithFilters } from 'types/tables';

export const getEntriesTableColumns = ({
  onFilter,
  onRemove,
}: ColumnsWithFilters): IColumn<Entry>[] => [
  {
    Header: 'ID',
    accessor: 'id',
    disableFilters: true,
    Cell: ({ row }: ICellValue<Entry>) => (
      <Link to={`${INITIAL_URL}/mifid/entry/${row.values.id}`}>
        {row.values.id}
      </Link>
    ),
    width: 60,
  },
  {
    Header: 'BO Trade',
    accessor: 'bo_trade',
    type: 'textInput',
    onFilter,
    onRemove,
  },
  {
    Header: 'BO Symbol',
    accessor: 'bo_symbol',
    type: 'textInput',
    onFilter,
    onRemove,
  },
  {
    Header: 'UID',
    accessor: 'uid',
    type: 'textInput',
    onFilter,
    onRemove,
  },
  {
    Header: 'Status',
    accessor: 'status_id',
    width: 50,
    type: 'textInput',
    onFilter,
    onRemove,
  },
];
