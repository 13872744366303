import * as React from 'react';

import { IconWrapper } from '../IconWrapper';
import { IconProps } from '../types';

const Subscribe = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      iconDefault={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M5.72 18.21a1 1 0 01-.72-.96v-4.5a1 1 0 01.72-.96l22-6.75A1 1 0 0129 6v18a.999.999 0 01-1.28.96l-22-6.75z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 19.523V24a1 1 0 001 1h4a1 1 0 001-1V8.637"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconHover={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M6.935 21.02a1 1 0 01-.944-.74l-1.164-4.347a1 1 0 01.447-1.114L24.777 2.605a1 1 0 011.485.596l4.659 17.387a1.001 1.001 0 01-.988 1.258L6.935 21.02z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.41 21.18l1.158 4.326a1 1 0 001.225.707l3.864-1.036a1 1 0 00.707-1.224l-3.977-14.84"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconActive={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M6.935 21.02a1 1 0 01-.944-.74l-1.164-4.347a1 1 0 01.447-1.114L24.777 2.605a1 1 0 011.485.596l4.659 17.387a1.001 1.001 0 01-.988 1.258L6.935 21.02z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.41 21.18l1.158 4.326a1 1 0 001.225.707l3.864-1.036a1 1 0 00.707-1.224l-3.977-14.84"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      iconDisabled={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M5.72 18.21a1 1 0 01-.72-.96v-4.5a1 1 0 01.72-.96l22-6.75A1 1 0 0129 6v18a.999.999 0 01-1.28.96l-22-6.75z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 19.523V24a1 1 0 001 1h4a1 1 0 001-1V8.637"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      {...restProps}
    />
  );
};

export default Subscribe;
