import { isStage, isLocalHost, isTwino, getApiUrl } from '../api';

const STAGE_ENV = 'emir-reports-stage.exante.eu';
const PROD_ENV = 'emir-reports.exante.eu';
const TWINO_ENV = 'compliance-reports.twinozone.com';

const RUN_UI_API_URL = window?.REG_REPORTING_UI?.api_urls?.EMIR;

export const getEmirEndpoint = () => {
  if (RUN_UI_API_URL) {
    return getApiUrl(RUN_UI_API_URL);
  }
  if (isTwino) {
    return getApiUrl(TWINO_ENV);
  }
  if (isStage || isLocalHost) {
    return getApiUrl(STAGE_ENV);
  }
  return getApiUrl(PROD_ENV);
};
