import { getApiUrl, isStage, isLocalHost, isTwino } from '../api';

const STAGE_ENV = 'security-lending-stage.exante.eu';
const PROD_ENV = 'security-lending.exante.eu';
const TWINO_ENV = 'security-lending.exante.eu';

const RUN_UI_API_URL = window?.REG_REPORTING_UI?.api_urls?.STOCK_LENDING;
export const getLendingEndpoint = () => {
  if (RUN_UI_API_URL) {
    return getApiUrl(RUN_UI_API_URL);
  }
  if (isTwino) {
    return getApiUrl(TWINO_ENV);
  }
  if (isStage || isLocalHost) {
    return getApiUrl(STAGE_ENV);
  }
  return getApiUrl(PROD_ENV);
};
export enum Urls {
  REPORTS = 'reports',
  REPORT_DOWNLOAD = 'reports/download',
  RECALLS = 'recalls',
  COUNTERPARTIES = 'counterparty',
  ACCOUNTS = 'accounts',
  SEND_RECALLS_TO_FTP = 'recalls/send_recalls_to_ftp',
  SECURITIES = 'assets',
  SEND_ASSETS_TO_FTP = 'recalls/send_available_assets_to_ftp',
}
