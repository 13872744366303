import { FC, useCallback, useMemo, useState } from 'react';
import {
  Table,
  Panel,
  useTableData,
  IOnFetchArguments,
  ISortBy,
  CloseButton,
} from 'react-ui-kit-exante';

import { defaultOrdering, pageSizes } from 'constants/tables';
import { EmirApiService, Task, TasksTable } from 'services/emir';
import { calculateCountOfPages } from 'utils';

import { RunScripts } from './RunScripts';
import { getTasksTableHeader } from './ScriptsPageUtils';

const api = new EmirApiService();

export const EmirScriptsPage: FC = () => {
  const [runIsShow, setRunIsShow] = useState(false);

  const getTasks = useCallback(
    (props: IOnFetchArguments) => api.getTasks(props),
    [],
  );

  const prepareFiltersForParams = useCallback(
    (params: Record<string, unknown>): Record<string, unknown> => {
      const filterParams = getTasksTableHeader({}).map(
        (column) => column.accessor,
      );
      return Object.keys(params).reduce(
        (acc, param) =>
          filterParams.includes(param as keyof Task)
            ? { ...acc, [param]: params[param] }
            : acc,
        {},
      );
    },
    [],
  );
  const tableData = useMemo(
    () => ({
      data: { onFetch: getTasks },
      filters: {
        prepareFiltersForParams,
      },
      pagination: { getDefaultPagination: () => ({ limit: 10, skip: 0 }) },
    }),
    [getTasks],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    setSorting,
    fetchData,
  } = useTableData<TasksTable>(tableData);

  const total = data?.pagination.total || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const columns = useMemo(
    () => getTasksTableHeader({ onFilter: setFilter, onRemove: removeFilter }),
    [data?.tasks],
  );

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
    }),
    [filters, resetFilters],
  );

  const handleSorting = useCallback(
    (sortingArray: ISortBy[]) => {
      setSorting(sortingArray);
    },
    [setSorting],
  );

  const panelAction = useMemo(
    () => (
      <CloseButton
        className="mb-2"
        onClick={() => setRunIsShow(false)}
        size="l"
      />
    ),
    [setRunIsShow],
  );

  const handleChangeIsShow = () => setRunIsShow(!runIsShow);

  return (
    <div className="mui-row">
      <div className={runIsShow ? 'mui-col-md-8' : 'mui-col-md-12'}>
        <Table
          title="Management Console"
          tableId="emirTasksTable"
          data={data?.tasks || []}
          onAdd={handleChangeIsShow}
          columns={columns}
          filteringProps={filteringProps}
          isLoading={isLoading}
          defaultSortBy={defaultOrdering}
          showScrollbar
          hasFilters
          hasPagination
          showTableInfo
          manualSortBy
          onSort={handleSorting}
          isFlexLayout
          pageSizes={pageSizes}
          serverPaginationProps={{
            pageIndex: page,
            pageCount,
            pageSize: limit,
            total,
            setPage,
            setPageSize: setLimit,
          }}
        />
      </div>
      <div className={!runIsShow ? 'd-none' : 'mui-col-md-4'}>
        <Panel title="Run scripts" action={panelAction}>
          <RunScripts onReloadData={fetchData} />
        </Panel>
      </div>
    </div>
  );
};
