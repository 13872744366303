import { FC, useCallback, useMemo } from 'react';
import {
  Table,
  useTableData,
  IOnFetchArguments,
  ISortBy,
} from 'react-ui-kit-exante';

import { defaultOrdering, pageSizes } from 'constants/tables';
import { Position, PositionsTable, EmirApiService } from 'services/emir';
import { calculateCountOfPages } from 'utils';

import { getPositionsTableColumns } from './PositionsPage.utils';

const api = new EmirApiService();

const parseNumberValue = (value: string) => Number.parseFloat(value).toString();

export const EmirPositionsPage: FC = () => {
  const getPositions = useCallback(
    (props: IOnFetchArguments) => api.getPositions(props),
    [],
  );

  const prepareFiltersForParams = useCallback(
    (params: Record<string, unknown>): Record<string, unknown> => {
      const filterParams = getPositionsTableColumns({}).map(
        (column) => column.accessor,
      );

      return Object.keys(params).reduce(
        (acc, param) =>
          filterParams.includes(param as keyof Position)
            ? { ...acc, [param]: params[param] }
            : acc,
        {},
      );
    },
    [],
  );

  const tableData = useMemo(
    () => ({
      data: { onFetch: getPositions },
      pagination: { getDefaultPagination: () => ({ limit: 10, skip: 0 }) },
      filters: {
        prepareFiltersForParams,
      },
    }),
    [getPositions],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    setSorting,
  } = useTableData<PositionsTable>(tableData);

  const pageCount = useMemo(
    () => calculateCountOfPages(data?.pagination.total || 0, limit),
    [limit, data?.pagination.total],
  );
  const positionItems = useMemo(
    () =>
      !data
        ? []
        : data.positions.map((item) => ({
            ...item,
            qty: parseNumberValue(item.qty),
            price: parseNumberValue(item.price),
            value: parseNumberValue(item.value),
            pnl: parseNumberValue(item.pnl),
          })),
    [data],
  );

  const columns = useMemo(
    () =>
      getPositionsTableColumns({ onFilter: setFilter, onRemove: removeFilter }),
    [positionItems],
  );

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
    }),
    [filters, resetFilters],
  );

  const handleSorting = useCallback(
    (sortingArray: ISortBy[]) => {
      setSorting(sortingArray);
    },
    [setSorting],
  );

  return (
    <Table
      title="Positions"
      tableId="emirPositions"
      data={positionItems}
      columns={columns}
      filteringProps={filteringProps}
      isLoading={isLoading}
      defaultSortBy={defaultOrdering}
      showScrollbar
      hasFilters
      hasPagination
      showTableInfo
      manualSortBy
      onSort={handleSorting}
      isFlexLayout
      pageSizes={pageSizes}
      serverPaginationProps={{
        pageIndex: page,
        pageCount,
        pageSize: limit,
        total: data?.pagination.total || 0,
        setPage,
        setPageSize: setLimit,
      }}
    />
  );
};
