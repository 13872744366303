import cn from 'classnames';
import { FC } from 'react';

import { DefaultThemeProvider } from '../../theme';
import { Loader } from '../Loader';
import { Skeleton } from '../Skeleton';

import { StyledButton } from './Button.styled';
import { IUIButtonProps } from './types';

export const Button: FC<IUIButtonProps> = ({
  color = 'primary',
  children,
  disabled = false,
  icon,
  loading = false,
  size = 'medium',
  showSkeleton = false,
  textColor = 'secondary',
  ...rest
}) => {
  const hasText = Boolean(children);
  const { fullWidth } = rest;
  const Icon = loading ? (
    <Loader size={hasText || size === 'small' ? 'm' : 'l'} />
  ) : (
    icon
  );

  let skeletonWidth: number;

  if (hasText) {
    skeletonWidth = size === 'small' ? 80 : 120;
  } else {
    skeletonWidth = size === 'small' ? 32 : 48;
  }

  return (
    <DefaultThemeProvider>
      {showSkeleton ? (
        <Skeleton
          height={size === 'small' ? 32 : 48}
          width={fullWidth ? '100%' : skeletonWidth}
        />
      ) : (
        <StyledButton
          disableRipple
          color={color}
          disabled={disabled || loading}
          startIcon={Icon}
          loading={loading}
          hasText={hasText}
          textColor={textColor}
          size={size}
          fullWidth={fullWidth}
          className={cn(`Button-${color}`, rest.className)} // static class for external stylization
          {...rest}
        >
          {children}
        </StyledButton>
      )}
    </DefaultThemeProvider>
  );
};
