export const convertFromExponential = (value: any) => {
  if (typeof value !== 'number') {
    return value;
  }

  return `${+value}`.replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/, (a, b, c, d, e) => {
    return e < 0
      ? `${b}0.${Array(1 - e - c.length).join('0')}${c}${d}`
      : b + c + d + Array(e - d.length + 1).join('0');
  });
};
