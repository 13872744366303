import { IColumn } from 'react-ui-kit-exante';

import { minColumnWidth as minWidth } from 'constants/tables';
import { SftrTrade } from 'services/mifir';
import { ColumnsWithFilters } from 'types/tables';

const columnWidth = 300;
export function getTradesTableColumns({
  onFilter,
  onRemove,
}: ColumnsWithFilters) {
  const columns: IColumn<SftrTrade>[] = [
    {
      Header: 'Uid',
      accessor: 'uid',
      minWidth: 150,
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Id',
      accessor: 'id',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Chain Id',
      accessor: 'chain_id',
      width: columnWidth,
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Trade date',
      accessor: 'trade_date',
      minWidth,
      type: 'dateRange',
      onFilter,
      onRemove,
    },
    {
      Header: 'Trade time',
      accessor: 'trade_time',
      width: columnWidth,
      minWidth,
      disableFilters: true,
    },
    {
      Header: 'Trade type',
      accessor: 'trade_type',
      width: columnWidth,
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Value date',
      accessor: 'value_date',
      minWidth,
      type: 'dateRange',
      onFilter,
      onRemove,
    },
    {
      Header: 'Account ID',
      accessor: 'account_id',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Status',
      accessor: 'status',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Side',
      accessor: 'side',
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Symbol ID',
      accessor: 'symbol_id',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'ISIN',
      accessor: 'isin',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Symbol Type',
      accessor: 'symbol_type',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Price',
      accessor: 'price',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Currency',
      accessor: 'currency',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Error',
      accessor: 'error',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Counterparty account ID',
      accessor: 'counterparty_account_id',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Execution counterparty',
      accessor: 'execution_counterparty',
      width: columnWidth,
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Settlement counterparty',
      accessor: 'settlement_counterparty',
      width: columnWidth,
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Status date',
      accessor: 'status_date',
      minWidth,
      type: 'dateRange',
      onFilter,
      onRemove,
    },
    {
      Header: 'Legal entity',
      accessor: 'legal_entity',
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Order ID',
      accessor: 'order_id',
      width: columnWidth,
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Order Pos',
      accessor: 'order_pos',
      width: columnWidth,
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
      width: columnWidth,
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Sent date',
      accessor: 'sent_date',
      width: columnWidth,
      minWidth,
      type: 'dateRange',
      onFilter,
      onRemove,
    },
    {
      Header: 'Timestamp',
      accessor: 'timestamp',
      width: columnWidth,
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Internal status',
      accessor: 'internal_status',
      width: columnWidth,
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Traded volume',
      accessor: 'traded_volume',
      width: columnWidth,
      minWidth,
      type: 'textInput',
      onFilter,
      onRemove,
    },
  ];
  return columns;
}
