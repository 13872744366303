import { useEffect } from 'react';

import { KitTheme, useTheme } from '../../../theme';
import {
  ActionCellStyled,
  CellStyled,
  CellTextContainerStyled,
  RowStyled,
} from '../Table.styled';
import { useSetAllEditableValuesContext } from '../contexts/EditableContext';
import { getCellPropsWithStyles } from '../helpers';
import { OptionsForInlineEdit } from '../types';

import { EditableCellInput } from './EditableCellInput/EditableCellInput';
import { DEFAULT_INPUT_TYPE } from './constants';
import { getIsEditable, hasEditParams } from './helpers';
import { EditableRowProps } from './types';

export const EditableRow = <Data extends object, Theme = KitTheme>({
  id: rowId,
  values,
  singleColored,
  rowProps,
  cells,
  getCellPropsExternal,
  getColumnPropsExternal,
  locale,
}: EditableRowProps<Data, Theme>) => {
  const setAllEditableRowValues = useSetAllEditableValuesContext<Data>();
  const theme = useTheme();

  useEffect(() => {
    setAllEditableRowValues(values);
  }, [setAllEditableRowValues, values]);

  return (
    <RowStyled
      data-row-id={rowId}
      data-name="table__body--editable-row"
      data-test-id="table__body--editable-row"
      isColoredRow={!singleColored}
      {...rowProps}
    >
      {cells.map((cell) => {
        const { column, getCellProps, value, render } = cell;
        const columnId = column?.id as keyof Data;
        const isActionsColumn = columnId === 'actions';
        const editParams = column.editParams;
        let options: OptionsForInlineEdit = [];

        if (
          editParams?.editable &&
          (editParams.inputType === 'select' ||
            editParams.inputType === 'multiSelect')
        ) {
          if (typeof editParams.options === 'function') {
            options = editParams.options(values);
          } else {
            options = editParams.options;
          }
        }

        const additionalCellProps = getCellProps(
          getCellPropsWithStyles([
            // @ts-expect-error -- we expect external theme to inherit KitTheme
            getCellPropsExternal(cell, theme),
            // @ts-expect-error -- we expect external theme to inherit KitTheme
            getColumnPropsExternal(column, theme),
          ]),
        );

        const alignItems = isActionsColumn
          ? 'flex-start'
          : additionalCellProps?.style?.alignItems || 'center';

        const CellComponent = isActionsColumn ? ActionCellStyled : CellStyled;

        return (
          <CellComponent
            depth={0}
            {...additionalCellProps}
            style={{
              ...additionalCellProps.style,
              alignItems,
            }}
            key={`${rowId}-${String(columnId)}`}
            data-test-id="table__body--editable-cell"
          >
            <CellTextContainerStyled title={value ?? ''}>
              {!isActionsColumn &&
              hasEditParams(column) &&
              column.editParams?.editable &&
              getIsEditable(cell) ? (
                <EditableCellInput
                  columnId={columnId as keyof Data}
                  type={column.editParams?.inputType || DEFAULT_INPUT_TYPE}
                  additionalInputProps={column.editParams.additionalInputParams}
                  options={options}
                  onChange={column.editParams.onChange}
                  values={values}
                  locale={locale}
                />
              ) : (
                render('Cell')
              )}
            </CellTextContainerStyled>
          </CellComponent>
        );
      })}
    </RowStyled>
  );
};
