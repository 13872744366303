import { ComponentType } from 'react';

import { blockNonNativeProps } from '../../../helpers';
import { styled } from '../../../theme';
import { ICloseButtonProps } from '../types';

import { sizeMap } from './constants';

export const StyledButton = styled('button', {
  shouldForwardProp: blockNonNativeProps('size'),
})(({ size }: ICloseButtonProps) => ({
  width: sizeMap[size || 'm'].width,
  height: sizeMap[size || 'm'].height,
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  padding: '0',
})) as ComponentType<ICloseButtonProps>;
